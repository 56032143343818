@use "../abstracts" as *;
@use "./forms" as *;
@use "../theme/input" as *;
@use "../theme/borders" as theme;
@use "../theme/colors" as *;

.input-group {
    border: $input-border-width solid theme.$border-color;
    border-radius: theme.$border-radius;
    background-color: $white;

    &:focus-within {
        border-color: $input-focus-border-color;
    }

    &-text {
        border: none;
        border-radius: $br-md;
        padding: $input-padding-y;

        &.transparent {
            background-color: transparent;
        }
    }

    // search fields, get tickets page - unlock offers
    .form-control {
        border: none;
        background-color: transparent;
        font-size: $body-2;

        &::placeholder {
            font-size: $body-2;
        }

        &:focus {
            border-color: transparent;
        }

        // navigation search field
        &-sm {
            font-size: $input-font-size-sm !important;

            &::placeholder {
                font-size: $input-font-size-sm !important;
            }
        }
    }

    &.input-group-error {
        @extend .error-border !optional;
    }

    &-sm {
        width: rem(104);

        .input-group-text,
        .form-control {
            height: 100%;
            min-height: auto;
            padding: $input-padding-y-sm $input-padding-x-sm;
        }
    }
}
