@use '../../../../../../../scss/abstracts' as *;
@use '../../../../../../../scss/layout/spacing' as *;
@use '../../../../../../../scss/theme/borders' as *;

#checkout-form {
	background-color: #ffffff;
	border-radius: $border-radius;
	.form-group {
		margin-bottom: $text-spacer-sm;
	}
}
