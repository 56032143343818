@use "./../../scss/abstracts" as *;
@use "./../../scss/layout" as *;
@use "./../../scss/theme" as *;

#login-signup-container {
    @include breakpoint-max(md) {
        gap: $text-spacer;
    }

    .error {
        color: #dc3545 !important;
    }

    .form-container {
        @include breakpoint-min(md) {
            max-width: 376px;
        }
    }

    .heading,
    .step-desc {
        margin-bottom: $text-spacer;
    }

    .title {
        @extend .normal--uppercase;
        margin-bottom: rem(14);
    }

    .subtitle {
        font-size: $caption;
        color: $muted;
        line-height: $line-height-base;
        font-weight: $fw-normal;
        margin: 0px;
    }

    .form-text {
        font-size: $caption;
        color: $muted;
        margin-top: $text-spacer;
        text-align: center;

        .btn {
            font-size: $caption;
        }
    }

    .code-container {
        > * {
            flex: 1;
            max-width: 79px;
        }
        .pincode {
            flex: 1;
            height: 58px;
            text-align: center;
            font-size: $body-2;
            padding: 0px;
        }
    }
}
