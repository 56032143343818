@use '../../../../scss/abstracts' as *;
@use '../../../../scss/layout' as *;

.additional_info {
	.card-body {
		display: flex;
		flex-direction: column;
		gap: rem(16);
	}

	.information_container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		gap: rem(8);

		&.qrcode {
			&::before {
				content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M10.6789 2.00003H4.32124C3.59167 2.00003 3.00003 2.59139 3.00003 3.3212V9.67894C3.00003 10.4088 3.59167 11.0001 4.32124 11.0001H10.6789C11.4085 11.0001 12.0001 10.4088 12.0001 9.67894V3.3212C12.0001 2.59139 11.4085 2.00003 10.6789 2.00003Z' fill='%233E8BF7'/%3E%3Cpath d='M20.679 12.0002H14.3214C13.5918 12.0002 13.0002 12.5915 13.0002 13.3213V19.6791C13.0002 20.4089 13.5918 21.0002 14.3214 21.0002H20.679C21.4086 21.0002 22.0002 20.4089 22.0002 19.6791V13.3213C22.0002 12.5915 21.4086 12.0002 20.679 12.0002Z' fill='%233E8BF7'/%3E%3Cpath d='M8.73012 18.4132H5.53997V15.2936C5.53997 14.5791 4.97127 14.0002 4.27 14.0002C3.56872 14.0002 3.00003 14.5791 3.00003 15.2936V19.7069C3.00003 20.4213 3.56872 21.0002 4.27 21.0002H8.73012C9.4314 21.0002 10.0001 20.4213 10.0001 19.7069C10.0001 18.9924 9.4314 18.4132 8.73012 18.4132Z' fill='%233E8BF7'/%3E%3Cpath d='M20.679 2.00003H14.3214C13.5918 2.00003 13.0002 2.59139 13.0002 3.3212V9.67894C13.0002 10.4088 13.5918 11.0001 14.3214 11.0001H20.679C21.4086 11.0001 22.0002 10.4088 22.0002 9.67894V3.3212C22.0002 2.59139 21.4089 2.00003 20.679 2.00003ZM19.3578 8.35777H15.6426V4.64237H19.3578V8.35777Z' fill='%233E8BF7'/%3E%3C/svg%3E");
			}
		}

		&.protection {
			&::before {
				content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.05132 3.81624C3.82629 4.22458 3 5.371 3 6.66229V12C3 17.502 8.56019 20.8417 10.9399 22.0192C11.6125 22.3519 12.3875 22.3519 13.0601 22.0192C15.4398 20.8417 21 17.502 21 12V6.66229C21 5.371 20.1737 4.22459 18.9487 3.81624L12.9487 1.81624C12.3329 1.61098 11.6671 1.61098 11.0513 1.81624L5.05132 3.81624ZM15.7071 10.7071C16.0976 10.3166 16.0976 9.68342 15.7071 9.29289C15.3166 8.90237 14.6834 8.90237 14.2929 9.29289L11 12.5858L9.70711 11.2929C9.31658 10.9024 8.68342 10.9024 8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L9.72721 14.1414C10.4302 14.8444 11.5698 14.8444 12.2728 14.1414L15.7071 10.7071Z' fill='%2345B36B'/%3E%3C/svg%3E");
			}
		}

		.ic_inner {
			display: flex;
			flex-direction: column;
			gap: $gap-xs;
			.card-title {
				line-height: 24px;
				margin-bottom: 0px;
			}
		}
	}
}
