@use '../../../../scss/layout' as *;

.card.myCard {
	.card-text {
		@extend %icon-text-box;
		gap: $gap-sm;
		&::before {
			content: url("data:image/svg+xml,%3Csvg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.6415 15.2445C15.0423 13.3001 16 11.1631 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 11.1631 2.95769 13.3001 4.35846 15.2445C5.74868 17.1742 7.45814 18.7503 8.67688 19.7485C8.87681 19.9122 9.12319 19.9122 9.32312 19.7485C10.5419 18.7503 12.2513 17.1742 13.6415 15.2445ZM10.5904 21.2957C13.1746 19.1791 18 14.4917 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 14.4917 4.82537 19.1791 7.40961 21.2957C8.34656 22.0631 9.65344 22.0631 10.5904 21.2957Z' fill='%23777E91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7ZM5 9C5 6.79086 6.79086 5 9 5C11.2091 5 13 6.79086 13 9C13 11.2091 11.2091 13 9 13C6.79086 13 5 11.2091 5 9Z' fill='%23777E91'/%3E%3C/svg%3E%0A");
		}
	}
	.venue-list {
		height: 72px;
	}
}
