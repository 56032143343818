@use './colors' as *;

$br-primary: 12px;
$br-secondary: 90px;
$br-md: 10px;
$br-sm: 8px;
$br-xs: 4px;
$border-width: 2px;
$border-color: $clr-neutral-6;
$box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
$box-shadow-popupCard: 0px -10px 30px -8px rgba(15, 15, 15, 0.12);

@function border($border-width: 1px, $color: $border-color, $border-style: solid) {
    @return $border-width $border-style $color;
}

@mixin full-width-border($border-width: 1px, $color: $border-color) {
    content: '';
    height: $border-width;
    position: absolute;
    left: 0;
    right: 0;
    background-color: $border-color;
    z-index: 1;
}

.event-image {
    border-radius: $br-md; // 10px

    // ticket card - 12px
    &-lg {
        border-radius: $br-primary;
    }
}

// add ons and search items
.br-sm,
.thumbnail {
    border-radius: $br-sm;
}
