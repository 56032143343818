@use '../../../../../../scss/abstracts' as *;

.ticket-error-dialog {
    position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 16px;
	padding: 32px;
	border: 2px solid #FFFFFF;
	background-color: #ffffff;
	width: 100%;
	max-width: 448px;
	&::backdrop {
		background: rgba(0, 0, 0, 0.3);
	}

	.ticket-error-content {
		display: flex;
		flex-direction: column;
		h4 {
			font-size: 24px;
			font-weight: 600;
			line-height: 32px;
			text-align: left;
			color: #23262F;
			margin-bottom: 16px;
		}
		span {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: #23262F;
		}
	}

	@include breakpoint-max(md) {
		border-radius: 12px;
		padding: 24px;
		max-width: calc(100% - 32px);
	}
}