@use '../../scss/abstracts' as *;

.action-btns.btn-group-flex {
	flex: 1;

	.btn {
		flex: 0 0 147px;

		@include breakpoint-min(md) {
			flex: 0 0 170px;
		}
	}
}
