@use "../../../../../../scss/abstracts" as *;
@use "../../../../../../scss/layout/typography" as *;
@use "../../../../../../scss/theme/colors" as *;
@use "../../../../../../scss/theme/links" as *;

.accessibility-filter {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    svg {
        min-width: 20px;
    }
}

.abcd-floating-card {
    padding: rem(8) rem(16);
}

// filter desktop cards
.abcfloating-card {
    display: flex;
    flex-direction: column;
    gap: rem(8);
    padding: rem(4) rem(8);

    .dropdown-item {
        padding: rem(8);
        text-align: start;
        border-radius: 8px;
        color: $body-color;
        font-size: $small;
        line-height: 24px;
        font-weight: $fw-medium;
        &:focus-within,
        &:hover {
            background-color: $gray-300;
            outline: border();
            color: $body-color;
        }
    }
}

.vjx-fb-icon-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 4px;
    svg {
        min-width: 20px !important;
    }
}

.vjx-fb-bt-styling {
    border-radius: 90px;
    padding: 8px 16px;
    border: 2px solid rgba(230, 232, 236, 1);
    background: rgba(252, 252, 253, 1);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(20, 20, 22, 1);
    &.active {
        color: $link-color !important;
        border: 2px solid rgba(62, 169, 247, 1);
        background: rgba(62, 169, 247, 0.08);
        color: rgba(62, 139, 247, 1);
        svg {
            fill: rgba(62, 139, 247, 1) !important;
            path {
                fill: rgba(62, 139, 247, 1) !important;
            }
        }
    }
}

.abcsort-btn {
    color: $clr-neutral-3 !important;
    background: none;
    border: none;
    gap: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(53, 57, 69, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    &::before {
        height: 24px;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M7 5C7 4.44772 6.55228 4 6 4C5.44772 4 5 4.44772 5 5V17.0858L3.70711 15.7929C3.31658 15.4024 2.68342 15.4024 2.29289 15.7929C1.90237 16.1834 1.90237 16.8166 2.29289 17.2071L4.58579 19.5C5.36683 20.281 6.63316 20.281 7.41421 19.5L9.70711 17.2071C10.0976 16.8166 10.0976 16.1834 9.70711 15.7929C9.31658 15.4024 8.68342 15.4024 8.29289 15.7929L7 17.0858V5Z' fill='%23777E91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 7C12 6.44772 12.4477 6 13 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H13C12.4477 8 12 7.55228 12 7Z' fill='%23777E91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 12C12 12.5523 12.4477 13 13 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H13C12.4477 11 12 11.4477 12 12Z' fill='%23777E91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 17C12 17.5523 12.4477 18 13 18H15C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16H13C12.4477 16 12 16.4477 12 17Z' fill='%23777E91'/%3E%3C/svg%3E");
    }
}
