@use '../abstracts' as *;
@use './colors' as *;
@use './buttons' as theme;

$navbar-padding-y: rem(12);

$nav-link-color: $text-muted;
$nav-link-hover-color: $body-color;
$nav-link-font-weight: theme.$btn-font-weight;
$nav-link-font-size: $small;

$navbar-light-toggler-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 16.5C4 15.9477 4.44772 15.5 5 15.5H19C19.5523 15.5 20 15.9477 20 16.5C20 17.0523 19.5523 17.5 19 17.5H5C4.44772 17.5 4 17.0523 4 16.5Z' fill='%23777E91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 8.5C4 7.94772 4.44772 7.5 5 7.5H19C19.5523 7.5 20 7.94772 20 8.5C20 9.05228 19.5523 9.5 19 9.5H5C4.44772 9.5 4 9.05228 4 8.5Z' fill='%23777E91'/%3E%3C/svg%3E");
$navbar-light-toggler-border-color: transparent;
