@use '../../../../scss/abstracts' as *;
@use '../../../../scss/layout/typography' as *;
@use '../../../../scss/layout/spacing' as *;
@use '../../../../scss/theme/colors' as *;
@use '../../../../scss/theme/borders' as *;

.abcvenue_section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	.venue_container {
		display: flex;
		flex-direction: column;
		gap: rem(24);
		margin-bottom: $text-spacer-xs;
		width: 100%;

		@include breakpoint-min(md) {
			flex-direction: row;
		}

		.venue_img {
			width: 120px;
			height: 88px;
			object-fit: cover;
			border-radius: $border-radius;
		}
		.venue_info {
			flex: 1;
		}
	}

	.venue_ac {
		width: 100%;
		.accordion_container {
			display: flex;
			flex-direction: column;
			will-change: transform;
			gap: rem(16);
			.accordion-item {
				border-radius: 16px;
				background-color: transparent;
				border: border(2px, $info); // Neutrals/7
			}
			.accordion-button {
				@extend .normal;
				@extend .normal-bold;
				line-height: 24px;
				font-style: normal;
				padding: rem(16);
				@include breakpoint-min(sm) {
					font-weight: $fw-bold;
					text-transform: uppercase;
					line-height: 16px;
					padding: rem(24);
				}
				&[aria-expanded="true"] {
					&::after {
						transform: rotate(180deg);
					}
				}

				&::after {
					width: 1.25em;
					line-height: 0;
					content: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM7 7L6.29289 7.70711C6.68342 8.09763 7.31658 8.09763 7.70711 7.70711L7 7ZM13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L13.7071 1.70711ZM0.292893 1.70711L6.29289 7.70711L7.70711 6.29289L1.70711 0.292893L0.292893 1.70711ZM7.70711 7.70711L13.7071 1.70711L12.2929 0.292893L6.29289 6.29289L7.70711 7.70711Z' fill='%236F767E'/%3E%3C/svg%3E%0A");
					transition: transform .35s ease;
					margin-left: auto;
				}
			}
			.accordion-body {
				font-family: $font-body;
				font-size: $body-2;
				font-style: normal;
				font-weight: $fw-normal;
				line-height: 24px;
				color: $clr-neutral-3; // Neutrals/3
				padding-left: rem(16);
				padding-right: rem(16);
				padding-bottom: rem(16);
				@include breakpoint-min(sm) {
					padding-left: rem(24);
					padding-right: rem(24);
					padding-bottom: rem(24);
				}
			}
		}
	}
}
