.vjx-yy {
    padding: 12px 16px;
    border: 1px solid #e6e8ec;
    background-color: #ffffff;
    border-radius: 12px;
    position: relative;

    .vjx-btn {
        position: absolute;
        right: -12px;
        top: -12px;
        height: 24px;
        width: 24px;
        padding: 0;
        margin-top: 0;
        border: 1.2px solid #e6e8ec;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;

        // This hover + focus is used in multiple places, should create re-usable scss for it
        &:hover {
            background-color: #e4e4e4;
        }
        &:focus {
            outline: 2px solid #e4e4e4;
            outline-offset: 2px;
        }
    }

    .vjx-yt {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 8px;
        border-bottom: 1px solid #f4f5f6;

        .vjx-ytt {
            text-align: start;
            display: flex;
            flex-direction: row;

            .vjx-pos1 {
                font-size: 12px;
                font-weight: 700;
                line-height: 12px;
                text-align: start;
                color: #b1b5c3;
            }

            .vjx-pos {
                display: flex;
                flex-direction: column;
                width: 70px;

                .vjx-pos2 {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #141416;
                }
            }
        }
        .vjx-ytr {
            display: flex;
            flex-direction: column;
            text-align: end;

            .vjx-ytp {
                font-family: "DM Sans", sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 16px;
                color: #23262f;
            }
            .vjx-yti {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: #777e90;
            }
        }
    }
    .vjx-yb {
        padding-top: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        .vjx-ybb {
            display: flex;
            flex-direction: row;
            gap: 6px;
            flex-shrink: 0;
        }
    }
}
