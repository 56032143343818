@use "../../../../scss/abstracts" as *;
@use "../../../../scss/layout/typography" as *;
@use "../../../../scss/layout/spacing" as *;

.new-checkout-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: rem(32);
    padding-bottom: rem(72);
    position: relative;
    @include breakpoint-max(md) {
        padding-top: 0;
        padding-bottom: rem(81);
    }
    @include breakpoint-min(md) {
        .collapse-inner {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    .great-wrapper {
        display: flex;
        flex-direction: column;
        gap: rem(16);
        .g-texts {
            font-family: $font-body;
            color: #000000;
            h5 {
                @extend .fs-md;
                @extend .fs-md-bold;
                line-height: 32px;
                margin-bottom: 0;
            }
            p {
                font-size: $small;
                line-height: 24px;
            }
            @include breakpoint-max(sm) {
                h5 {
                    font-size: $body-2;
                    line-height: 24px;
                }
            }
        }
    }
}
