@use '../../../../../../../../../scss/abstracts' as *;
@use '../../../../../../../../../scss/layout/container' as *;

.mapsheet {
    position: relative;
    min-width: 422px;
    max-width: 422px;
    height: 100%;
    left: unset;
    box-shadow: 0px 4px 64px 0px #0000001C;
    border: 0px solid #FFFFFF;
    background-color: #FCFCFD;
    padding: 0;
    margin: 0;
    .mapsheet-inner {
        display: flex;
        flex-direction: column;
        height: 100%;

        .content {
            flex: 1 1 auto;
            overflow-y: auto; // Ensure it scrolls if it overflows
            // overflow-x: visible;
            // @include remove-scrollbar();
            padding: 24px 24px 12px 24px;
            .sd-c {
                background-color: #FCFCFD;
                height: 100%;
                width: 100%;
            }
        }
    }

    @include breakpoint-max(md) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-width: unset;
        max-width: unset;
        width: 100%;
        height: auto;
    }
}
