@use "../abstracts" as *;

@mixin hover-effect() {
    background-color: $clr-lightGray;
    border-radius: $br-sm;
}

.hover-effect:hover,
.hover-effect:focus {
    @include hover-effect();
}

$nav-link-padding-y: rem(12);
$nav-link-padding-x: rem(16);
$nav-link-margin: 0px rem(-16);
