

.vjx-sb {
    padding: 0 24px 24px 24px;
    display: flex;
    flex-direction: column;
    background-color: #FCFCFD;
    border-top: 1px solid #E6E8EC;
    
    .vjx-sbt {
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .vjx-sbl {
            display: flex;
            flex-direction: column;
            align-items: start;

            .vjx-ss1 {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: #23262F;
            }

            .vjx-ss2 {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: #23262F;
            }
        }

        .vjx-ss3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: #23262F;
        }
    }

    .vjx-btn-custom {
        margin-top: 0;
        padding: 16px 24px;
    }
}