@use '../../../scss/theme/modal' as *;
@use '../../../scss/abstracts' as *;
@use '../../../scss/layout' as *;

#event-modal {
	.modal-content {
		@include breakpoint-min(lg) {
			max-width: 569px;
		}
	}
	.event-details {
		padding: $modal-inner-padding;
		padding-top: em(15);
		padding-bottom: em(15);
		border-bottom: border();

		.event-name {
			@extend %text-truncate;
		}
	}

	.event-image {
		width: 195px;
		height: 195px;
	}
}
