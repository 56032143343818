@use "../../scss/abstracts" as *;
@use "../../scss/layout/spacing" as *;
@use "../../scss/layout/container" as *;

#view-package {
    height: 100%;
    @include breakpoint-max(lg) {
        flex-direction: column;
        gap: rem(24);
    }

    .card-body {
        @include breakpoint-min(md) {
            padding: rem(32);
        }

        .card-text {
            line-height: 24px;
        }
    }

    .about-card {
        .card-title {
            @include breakpoint-min(lg) {
                font-size: rem(48);
            }
        }

        .card-subtitle {
            font-weight: $fw-semi-bold;
            @include breakpoint-min(lg) {
                font-size: $body-1;
            }
        }

        .location {
            font-weight: $fw-semi-bold;
            @include breakpoint-min(lg) {
                font-size: $body-1;
            }
        }

        ul {
            li {
                list-style-type: disc;
                list-style-position: inside;
            }
        }
    }

    .seat-quantity {
        display: flex;
        @include breakpoint-max(lg) {
            flex-direction: column;
        }
        .form-select {
            @include breakpoint-min(lg) {
                width: 50%;
            }

            @include breakpoint-min(xl) {
                width: 25%;
            }
        }
    }

    .events-list {
        // border-top: border();
        // padding-top: $text-spacer-sm;

        ul {
            @include breakpoint-min(lg) {
                @include scrollable();
            }
        }
    }
}
