@use "../abstracts" as *;

%img-background {
    background-repeat: no-repeat;
    background-position: center;
}

%img-psuedo-icon {
    content: "";
    @extend %img-background !optional;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.lottie-bg-bottom {
    .lottie {
        left: 50%;
        transform: translate(-50%);
        bottom: 10%;
    }
}

.bg-bottom-100 {
    width: 100%;
    background-position: center bottom;
}

.lottie-bg-bottom-100 {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.lottie-top {
    .lottie {
        left: 50%;
        transform: translateX(-50%);
        top: 10%;
    }

    .card-content {
        margin-top: auto;
    }
}

.bg-top {
    background-position: center 19%;
    @include breakpoint-max(md) {
        background-position: center 10%;
    }
    .card-content {
        margin-top: auto;
    }
}

.lottie-bg-top {
    .lottie {
        left: 50%;
        transform: translateX(-50%);
        top: 19%;

        @include breakpoint-max(md) {
            left: 50%;
            transform: translateX(-50%);
            top: 10%;
        }
    }

    .card-content {
        margin-top: auto;
    }
}
