@use "../../scss/abstracts" as *;
@use "../../scss/theme/colors" as *;
@use "../../scss/layout/spacing" as *;
@use "../../scss/layout/typography" as *;
@use "../../scss/layout/container" as *;

#package-event-list {
    #details-btn {
        flex-shrink: 0;
        svg {
            width: 16px;
        }

        @include breakpoint-max(md) {
            padding: 0px;
            border: 0px;

            &:hover {
                background-color: transparent;
            }
        }
    }
}
