@use '../abstracts' as *;
@use './container' as *;
@use './spacing' as *;
@use './typography' as *;
@use './buttons' as *;
@use '../theme/borders' as *;

.modal {
	line-height: 1.5;

	p {
		+ p {
			margin-top: $text-spacer-sm;
		}
	}

	// my tickets popups
	&--popup {
		@include breakpoint-max(md) {
			box-shadow: $box-shadow-popupCard;
			height: 626px;
			width: 100vw;

			.modal-dialog {
				margin: 0px;
				height: 100%;
				max-width: 100%;
			}

			.modal-content {
				height: 100%;
				border-radius: $border-radius $border-radius 0px 0px;
			}
		}
	}

	&-title {
		font-size: $small;
	}

	&-body-heading {
		margin-bottom: $text-spacer-sm;

		&-title {
			font-size: $body-1;
			margin-bottom: $text-spacer-sm;
			font-weight: $fw-semi-bold;
		}

		&--with-border {
			padding-bottom: $text-spacer-sm;
			margin-bottom: $text-spacer-sm;
			border-bottom: border();
		}

		p {
			font-size: $small;
		}
	}

	&-dialog-scrollable {
		.modal-header {
			margin-bottom: $text-spacer-xs;
		}

		.modal-body {
			display: flex;
			flex-direction: column;
			padding-top: 0px !important;
			@include remove-scrollbar();
		}
	}

	// used for ticket modals
	&-lg {
		$m-x: rem(32);
		$pad-y: rem(24);

		padding-left: 0px;
		padding-right: 0px;

		.modal {
			&-header {
				padding: rem(24) 0px;
				margin-right: $m-x;
				margin-left: $m-x;

				.modal-title {
					font-size: $body-1;
				}
			}

			&-body {
				padding: $pad-y 0px;
				margin: 0px $m-x;
				display: flex;
				flex-direction: column;
			}

			&-footer {
				padding: em(24) 0px;
				margin: 0px $m-x;
			}
		}
	}

	// used for checkout modals - ARE WE USING ?
	&-xl {
		$padding: rem(32);

		.modal {
			&-body {
				padding: $padding;
				display: flex;
				flex-direction: column;

				&-heading-title {
					@extend .headline-4 !optional;
				}
			}
		}
	}

	.checkout-modal {
		@extend .modal-xl !optional;

		.modal-heading {
			margin-bottom: 0px;
		}
	}

	.btn-group-flex {
		gap: $text-spacer-xs;
		margin-top: $text-spacer-sm;

		&.hstack {
			> .btn {
				flex: 0;

				&:last-of-type {
					flex: 1;
				}
			}
		}

		&.vstack {
			flex: 0;

			> .btn {
				width: 100%;
			}
		}
	}
}
