@use "../../scss/abstracts" as *;

.ticket-card {
    box-shadow: $box-shadow;

    .event-image-lg {
        width: 217px;
        height: 217px;
    }

    .details {
        .card-title {
            font-size: $body-2;
            margin-bottom: rem(3);
        }

        .event-details {
            font-size: $caption;
            margin-bottom: rem(8);
        }
    }
    #apple-wallet-btn {
        border: none;
        border-radius: 6px;
        padding: 0;
        img {
            width: 100%;
        }
    }
    #google-wallet-btn {
        border: none;
        border-radius: 6px;
        margin-left: 0;
        padding: 0;
        img {
            width: 100%;
        }
    }
    #google-wallet-btn:active,
    #apple-wallet-btn:active {
        border: 2px solid black;
    }
}
