@use '../../../../scss/abstracts' as *;
@use '../../../../scss/layout/typography' as *;
@use '../../../../scss/theme/colors' as *;
@use '../../../../scss/theme/borders' as *;

.vjx-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: rem(8);
    padding-bottom: rem(16);
    h6 {
        color: #000000;
        @extend .fs-md;
        @extend .fs-md-bold;
        line-height: 32px;
        margin-bottom: 0;
    }
    img {
        height: 32px;
        width: 32px;
    }

    .tb-close {
        &:hover {
            background-color: #e4e4e4;
        }
        &:focus {
            outline: 2px solid #e4e4e4;
            outline-offset: 2px;
        }
    }

    &.--seatmapmodal {
        @include breakpoint-max(md) {
            display: none;
        }
    }
}