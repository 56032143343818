@use "../../../../scss/abstracts" as *;
@use "../../../../scss/layout/typography" as *;
@use "../../../../scss/layout/container" as *;
@use "../../../../scss/theme/colors" as *;
@use "../../../../scss/theme/borders" as *;

// Hide scroll when dialog is open
body:has(.dialog-base[open]) {
    overflow: hidden;
}

.dialog-base {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 16px;
    padding: rem(24);
    border: border($border-width, #ffffff);
    background-color: #ffffff;
    width: 100%;
    max-width: 630px;
    @include remove-scrollbar();

    .cb-content {
        border-radius: 20px;
        border: border($border-width, #ffffff);
        background-color: #ffffff;
    }
    &--blurred {
        background-color: rgba(255, 255, 255, 0.84);
        backdrop-filter: blur(162px);
        will-change: transform;
        .cb-content {
            border-radius: 20px;
            border: none;
            background-color: transparent;
        }
    }
    &::backdrop {
        background: rgba(0, 0, 0, 0.3);
    }

    @include breakpoint-max(md) {
        background-color: rgba(255, 255, 255, 0.84);
        backdrop-filter: blur(162px);
        will-change: transform;
        max-width: 100vw;
        width: 100%;
        margin: auto 0 0 0;
        border-radius: 16px 16px 0 0;
        position: fixed;
        .cb-content {
            position: relative;
            padding: rem(16);
            .card-body {
                // padding: 0;
            }
        }
        &--blurred {
            .cb-content {
                padding: 0;
            }
        }
    }
}
