

.vjx-td {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .vjx-tdd {
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #E6E8EC;

        .vjx-tdd-s {
            padding-top: 4px;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            columns: #23262F;

        }
    }

    .vjx-td-desc {
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;

        .vjx-td-desc-1 {
            padding-bottom: 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: #141416;
        }

        .vjx-td-desc-2 {
            font-size: 14px;
            font-weight: 400;
            line-height: 23.8px;
            color: #777E90;
        }
    }

    .vjx-tcc {
        padding-bottom: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 24px;

        .vjx-tcc-span {
            font-size: 14px;
            font-weight: 400;
            line-height: 23.8px;
            color: #23262F;
        }
        .vjx-tcc-p {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #141416;
            .vjx-tcc-p-span {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: #777E90;
            }
        }
        .form-select {
            width: 128px;
        }
    }
}