

.vjx-sheettop-back {
    position: relative;
    width: 100%;
    text-align: center;
    padding-bottom: 24px;

    .vjx-td-btn {
        padding: 0;
        border-radius: 4px;
        background-color: transparent;
        outline: none;
        border: none;
        position: absolute;
        left: 0;
        &:hover {
            background-color: #e4e4e4;
        }
        &:focus {
            outline: 2px solid #e4e4e4;
            outline-offset: 2px;
        }
    }
    h5 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #000000;
    }
}

.vjx-sheettop-noback {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #141416;
    padding-bottom: 24px;
}