@use "../../scss/abstracts" as *;
@use "../../scss/layout" as *;
@use "../../scss/theme/colors" as *;

.home-wrapper {
    .secondary-features {
        background: #fff;
        padding-bottom: $spacer-sm;
        @include breakpoint-min(lg) {
            padding-bottom: rem(210);
        }
    }

    .partners,
    .feature-cards {
        background-color: $gray-300;
    }

    .partners {
        padding: rem(60) 0;

        .partners-container {
            @include breakpoint-min(lg) {
                flex-wrap: wrap;
            }
        }

        .card {
            border-radius: 16px;
            background-color: #fff;
            min-width: 0;

            @include breakpoint-max(lg) {
                width: 100%;

                .card-img {
                    width: 40%;
                }
            }

            @include breakpoint-min(lg) {
                &#partner {
                    &-1 {
                        .card-img {
                            width: 105px;
                        }
                    }

                    &-2 {
                        .card-img {
                            width: 110px;
                        }
                    }

                    &-3 {
                        .card-img {
                            width: 98px;
                        }
                    }

                    &-4 {
                        .card-img {
                            width: 88px;
                        }
                    }

                    &-5 {
                        .card-img {
                            width: 86px;
                        }
                    }
                    &-6 {
                        .card-img {
                            width: 155px;
                        }
                    }
                }
            }

            &-body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: $text-spacer;
                gap: rem(16);
            }
        }
    }

    .cta {
        background: linear-gradient(180deg, $gray-300 0%, #fff 100%);
    }

    .display-1 {
        line-height: 1.2;
        @extend .headline-2 !optional;

        @include breakpoint-min(md) {
            width: 18ch;
            margin: auto;
            font-size: rem(65);
        }

        @include breakpoint-min(lg) {
            font-size: rem(80);
        }

        @include breakpoint-min(xxl) {
            font-size: rem(96);
            line-height: 120px;
        }
    }

    .display-text {
        color: $text-muted;
        font-weight: $fw-semi-bold;
        @include breakpoint-min(md) {
            font-size: $body-1;
            text-align: center;
        }
    }

    .text-gradient {
        -webkit-background-clip: text;
        -ms-background-clip: text;
        -moz-background-clip: text;
        -o-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -ms-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        -o-text-fill-color: transparent;
        text-fill-color: transparent;
    }

    .linear-gradient {
        background-image: linear-gradient(90deg, #3ea9f7 0%, #7f3cac 39.62%);
    }

    .hero-linear-gradient {
        background-image: linear-gradient(90deg, #885df5 59.99%, #3ea9f7 121.22%);
    }

    .text-primary {
        color: #3ea9f7 !important;
    }
    .tat {
        width: 22ch;
        margin: auto;
        opacity: 0;
    }
    .text_appear_two {
        animation: textTwoIntersect 0.8s forwards;
    }
    @keyframes textTwoIntersect {
        100% {
            opacity: 1;
            transform: translateY(0%);
        }
    }
    .display-4 {
        font-size: rem(28);

        @include breakpoint-min(md) {
            width: 22ch;
            margin: auto;
            font-size: rem(42);
        }

        @include breakpoint-min(lg) {
            font-size: rem(54);
        }
        @include breakpoint-min(xxl) {
            font-size: rem(66);
        }
    }
    .card_appear {
        animation: cardIntersect 0.8s forwards;
    }
    @keyframes cardIntersect {
        100% {
            transform: translateY(0%);
        }
    }
    // flip card
    .flip-card-outer {
        width: 100%;
        height: 640px;
        cursor: pointer;
        transform: translateY(7.5%);
        @include breakpoint-max(md) {
            height: 540px;
        }
        &-sm {
            height: 430px;
            width: 100%;

            .frontY,
            .frontX {
                text-align: center;
            }
        }
        // layer that rotates the flip card
        .flip-card-inner {
            position: relative;
            width: inherit;
            height: inherit;
            border: none;
            // Card has a bg if not otherwise removed
            background-color: transparent;

            // FLIPCARD
            -ms-perspective: 1600px;
            -webkit-perspective: 1600px;
            -moz-perspective: 1600px;
            -o-perspective: 1600px;
            perspective: 1600px;
            transition-delay: 0.3s;
            transform: translateY(0);
            transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1),
                -webkit-transform 1s cubic-bezier(0.39, 0.575, 0.565, 1);
            transition-property: transform, -webkit-transform;
            // FLIPCARD END

            // Fix bug with br on rotateY
            border-radius: 30px;
            &.showBack {
                .inner-card.frontY {
                    -webkit-transform: rotateY(180deg);
                    -ms-transform: rotateY(180deg);
                    -moz-transform: rotateY(180deg);
                    -o-transform: rotateY(180deg);
                    transform: rotateY(180deg);
                }
                .inner-card.backY {
                    -webkit-transform: rotateY(0deg);
                    -ms-transform: rotateY(0deg);
                    -moz-transform: rotateY(0deg);
                    -o-transform: rotateY(0deg);
                    transform: rotateY(0deg);
                }
                .inner-card.frontX {
                    -webkit-transform: rotateX(180deg);
                    -ms-transform: rotateX(180deg);
                    -moz-transform: rotateX(180deg);
                    -o-transform: rotateX(180deg);
                    transform: rotateX(180deg);
                }
                .inner-card.backX {
                    -webkit-transform: rotateX(0deg);
                    -ms-transform: rotateX(0deg);
                    -moz-transform: rotateX(0deg);
                    -o-transform: rotateX(0deg);
                    transform: rotateX(0deg);
                }
            }
            .inner-card {
                &.frontY,
                &.backY,
                &.frontX,
                &.backX {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    // FLIPCARD
                    transition-property: transform, -webkit-transform;
                    -ms-backface-visibility: hidden;
                    -webkit-backface-visibility: hidden;
                    -moz-backface-visibility: hidden;
                    -o-backface-visibility: hidden;
                    backface-visibility: hidden;
                    transition-duration: 0.8s;
                    -webkit-transition-timing-function: ease-in-out;
                    transition-timing-function: ease-in-out;
                    // FLIPCARD END

                    // Without this absolute positioned element will show on both sides
                    overflow: hidden;
                }
                &::after {
                    position: absolute;
                    bottom: 15px;
                    right: 20px;
                }
                &.card--darker,
                &.card--dark-gray,
                &.card--secondary,
                &.card--black {
                    &::after {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
                            brightness(103%) contrast(103%);
                    }
                }
                &.frontY {
                    z-index: 2;
                    -webkit-transform: rotateY(0deg);
                    -ms-transform: rotateY(0deg);
                    -moz-transform: rotateY(0deg);
                    -o-transform: rotateY(0deg);
                    transform: rotateY(0deg);
                }
                &.frontX {
                    z-index: 2;
                    -webkit-transform: rotateX(0deg);
                    -ms-transform: rotateX(0deg);
                    -moz-transform: rotateX(0deg);
                    -o-transform: rotateX(0deg);
                    transform: rotateX(0deg);
                }
                &.frontY,
                &.frontX {
                    &::after {
                        content: url("../../assets/icons/plus-square.svg");
                    }
                }
                &.backY {
                    -webkit-transform: rotateY(-180deg);
                    -ms-transform: rotateY(-180deg);
                    -moz-transform: rotateY(-180deg);
                    -o-transform: rotateY(-180deg);
                    transform: rotateY(-180deg);
                }
                &.backX {
                    -webkit-transform: rotateX(-180deg);
                    -ms-transform: rotateX(-180deg);
                    -moz-transform: rotateX(-180deg);
                    -o-transform: rotateX(-180deg);
                    transform: rotateX(-180deg);
                }
                &.backY,
                &.backX {
                    .card-title {
                        font-size: $body-2;
                        margin-bottom: $text-spacer-xs;
                        @include breakpoint-min(md) {
                            font-size: $body-1;
                        }
                    }
                    &::after {
                        content: url("../../assets/icons/minus-square.svg");
                    }
                    .card-text {
                        color: $body-color;
                        line-height: 1.8;
                        @include breakpoint-max(md) {
                            font-size: $small;
                        }
                    }
                    &.card--darker,
                    &.card--black,
                    &.card--dark-gray {
                        .card-text {
                            color: $gray-200;
                        }
                    }
                    &.card--secondary {
                        .card-text {
                            color: $white;
                        }
                    }
                }
                .card-content {
                    display: flex;
                    flex-direction: column;
                    margin-top: 0px;
                    transform: translateY(50%);

                    .badge {
                        width: max-content;
                    }
                }
                .text_appear {
                    animation: textIntersect 0.8s forwards;
                }
                @keyframes textIntersect {
                    100% {
                        transform: translateY(0%);
                    }
                }
            }
        }
    }

    .lottie {
        position: absolute;
        width: 80%;
        // top: 0;
        // right: 0;
        // transform: scale(0.9);

        &::-webkit-media-controls-panel {
            display: none !important;
        }
    }
    .feature-cards,
    .secondary-features,
    .testimonials,
    .partners,
    .cta {
        @include full-width();
    }
    .inner-card:not(.card-sm) {
        &.frontY,
        &.frontX {
            @extend %img-background;
        }
    }

    .col-12:not(.full-lottie) {
        .inner-card {
            &.frontY,
            &.frontX {
                .lottie {
                    max-width: 36%;
                    right: 0;
                    @include breakpoint-max(md) {
                        width: 80%;
                        height: auto;
                        right: auto;
                        top: 5%;
                        max-width: unset !important;
                    }
                    @media screen and (min-width: 500px) and (max-width: 768px) {
                        width: 400px;
                        max-width: 70% !important;
                    }
                    @include breakpoint-inBetween(md, lg) {
                        width: 400px;
                        height: auto;
                        right: 26%;
                        left: 26%;
                        transform: translate(-50px, -50px);
                        top: 10%;
                    }
                }
                @include breakpoint-min(lg) {
                    .card-content {
                        width: 64%;
                    }
                }
            }
        }
    }

    #map-creation,
    #fees {
        .inner-card {
            &.frontY,
            &.frontX {
                @include breakpoint-min(lg) {
                    .card-subtitle {
                        max-width: 20ch;
                    }
                }
            }
        }
    }

    #map-creation {
        .inner-card {
            &.frontY,
            &.frontX {
                .card-title {
                    width: 14ch;
                }
                .lottie {
                    max-width: 46%;

                    @include breakpoint-max(md) {
                        transform: translate(-50px, -50px);
                    }

                    @media screen and (max-width: 400px) {
                        top: 13%;
                    }
                    @include breakpoint-max(md) {
                        right: 23%;
                        left: 23%;
                    }
                }
            }
        }
    }

    #season-pass,
    #facility-fees,
    #pos {
        .inner-card {
            &.frontY,
            &.frontX {
                @extend .lottie-bg-bottom;
            }
        }
    }

    #facility-fees {
        .inner-card {
            &.frontY,
            &.frontX {
                .lottie {
                    @media screen and (min-width: 500px) and (max-width: 768px) {
                        bottom: 0px;
                    }
                }
            }
        }
    }

    #flex-packs,
    #ticket-scan,
    #checkout,
    #sell,
    #data {
        .inner-card {
            &.frontY,
            &.frontX {
                @extend .lottie-top;
            }
        }
    }

    #flex-packs {
        .inner-card.frontY {
            .lottie {
                top: 16%;
            }
        }
    }

    #support {
        .inner-card {
            &.frontY,
            &.frontX {
                @include breakpoint-min(md) {
                    .card-title {
                        width: 16ch;
                    }
                }
            }
        }
    }

    #revenue {
        .inner-card {
            &.frontY,
            &.frontX {
                .lottie {
                    @include breakpoint-max(md) {
                        top: 0%;
                    }

                    @media screen and (min-width: 400px) and (max-width: 500px) {
                        top: -4%;
                    }
                }
            }
        }
    }

    #sell {
        .inner-card {
            &.frontY,
            &.frontX {
                .lottie {
                    height: auto;
                }
            }
        }
    }
    #pos {
        .inner-card {
            &.frontY,
            &.frontX {
                .card-content {
                    gap: $gap-sm;
                }

                .lottie {
                    @media screen and (min-width: 400px) and (max-width: 768px) {
                        bottom: 0;
                    }

                    @include breakpoint-min(md) {
                        width: 66%;
                    }
                }
            }
        }
    }
    #data {
        .inner-card {
            &.frontY,
            &.frontX {
                align-items: center;
                .lottie {
                    width: 75%;
                    height: auto;
                    @include breakpoint-max(md) {
                        height: auto;
                    }
                    @include breakpoint-min(lg) {
                        width: 80%;
                    }
                }
            }
        }
    }
    #fees {
        .inner-card {
            &.frontY,
            &.frontX {
                .lottie {
                    width: auto !important;
                    height: 110%;
                    right: -330px;
                    top: -5%;
                    @include breakpoint-max(md) {
                        height: auto;
                        right: 0;
                        top: 0;
                    }
                    div {
                        height: 100%;
                    }
                }
            }
        }
    }

    .secondary-features,
    .testimonials,
    .partners {
        @include breakpoint-max(md) {
            @include full-width();
        }
    }

    .secondary-features-grid,
    .testimonials-container,
    .partners-container {
        @include breakpoint-min(lg) {
            padding-right: rem(12);
            padding-left: rem(12);
        }
    }

    .testimonials {
        padding: rem(98) 0px;

        .card {
            border: none;
            height: 363px;
            min-width: 0;
            flex: 1;

            &-body {
                display: flex;
                flex-direction: column;
                padding: $text-spacer-sm;
            }

            blockquote {
                &::before {
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='25' viewBox='0 0 36 25' fill='none'%3E%3Cpath d='M20.2585 15.1958C20.2585 12.6489 20.6503 10.4611 21.434 8.63257C22.2176 6.73869 23.2299 5.204 24.4707 4.02849C25.7115 2.78767 27.1156 1.90604 28.683 1.38359C30.3156 0.795831 31.9156 0.501953 33.483 0.501953V3.93053C31.785 3.93053 30.185 4.51828 28.683 5.69379C27.2462 6.80399 26.3972 8.33869 26.136 10.2979C26.3319 10.2326 26.5605 10.1673 26.8217 10.102C27.0176 10.0366 27.2462 9.97134 27.5074 9.90604C27.834 9.84073 28.1932 9.80808 28.585 9.80808C30.5442 9.80808 32.1768 10.5591 33.483 12.0611C34.7891 13.4979 35.4421 15.1958 35.4421 17.155C35.4421 19.1142 34.7564 20.8448 33.385 22.3469C32.0789 23.7836 30.3156 24.502 28.0952 24.502C25.6136 24.502 23.687 23.5877 22.3156 21.7591C20.9442 19.8652 20.2585 17.6775 20.2585 15.1958ZM0.666626 15.1958C0.666626 12.6489 1.05846 10.4611 1.84214 8.63257C2.62581 6.73869 3.63805 5.204 4.87887 4.02849C6.11969 2.78767 7.52377 1.90604 9.09111 1.38359C10.7238 0.795831 12.3238 0.501953 13.8911 0.501953V3.93053C12.1932 3.93053 10.5932 4.51828 9.09111 5.69379C7.65438 6.80399 6.8054 8.33869 6.54418 10.2979C6.7401 10.2326 6.96867 10.1673 7.22989 10.102C7.42581 10.0366 7.65438 9.97134 7.91561 9.90604C8.24214 9.84073 8.60132 9.80808 8.99316 9.80808C10.9523 9.80808 12.585 10.5591 13.8911 12.0611C15.1972 13.4979 15.8503 15.1958 15.8503 17.155C15.8503 19.1142 15.1646 20.8448 13.7932 22.3469C12.487 23.7836 10.7238 24.502 8.50336 24.502C6.02173 24.502 4.0952 23.5877 2.72377 21.7591C1.35234 19.8652 0.666626 17.6775 0.666626 15.1958Z' fill='%23FFD166'/%3E%3C/svg%3E");
                    display: block;
                    margin-bottom: rem(16);
                }
            }

            .vstack {
                justify-content: flex-end;

                > * {
                    text-align: right;
                }
            }
        }
    }
    #guest-list {
        .inner-card {
            &.frontY,
            &.frontX {
                @extend %img-background;
                background-position: center calc(100% - 40%);
                background-image: url("../../assets/list.svg");

                .card-title {
                    width: 10ch;
                    margin: auto;
                }
            }
        }
    }
    #mobile {
        .inner-card {
            &.frontY,
            &.frontX {
                @extend %img-background;
                background-position: center calc(100% - 40%);
                background-image: url("../../assets/qrcode.svg");
            }
        }
    }
    .cta {
        padding: rem(80) 0px rem(100);
        @include breakpoint-min(lg) {
            padding: rem(112) 0px rem(122);
        }
    }
    .testimonials_inner_div,
    .cta_inner_div,
    .partners_inner_div {
        opacity: 0;
        transform: translateY(100%);
    }
}
