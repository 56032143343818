@use "../../../../../../scss/abstracts" as *;

.vjx-ga-ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.vjx-ga-ticket {
    border: none;
    width: 100%;
    background-color: #fcfcfd;
    border: 1px solid #e6e8ec;
    padding: 12px;
    border-radius: 16px;
    cursor: pointer;
    &.--active {
        outline: 2px solid #3ea9f7;
    }

    .badge-quickpick {
        margin-top: rem(5);
    }

    .vjx-ticket-type {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #23262f;
    }

    .vjx-ga-txt-col {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .vjx-ticket-price {
            // Supposed to be font-family: Work Sans;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: -0.02em;
            color: #23262f;
            align-self: flex-end;
        }
        .vjx-ticket-pass {
            font-size: 14px;
            font-weight: 400;
            line-height: 23.8px;
            text-align: left;
            color: #353945;
        }
        .vjx-ticket-taxes {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #777e90;
            white-space: nowrap;
        }
    }
    .vjx-locked-left-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .vjx-ticket-type {
            line-height: 28px;
        }
    }
    .vjx-collapse-one {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: rem(10);
        margin-top: rem(10);
        border-top: 1px solid #e6e8ec;
        .vjx-quantity-button {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }

        @include breakpoint-max(md) {
            width: 100%;
            flex-direction: column-reverse;
            align-items: flex-start;

            .vjx-quantity-button {
                width: 100%;
                justify-content: space-between;
                padding-bottom: 12px;
            }
        }
    }
    .vjx-collapse-two {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        span {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #353945;
        }
    }
}
