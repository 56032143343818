@use "../../../../scss/abstracts" as *;

.abcbanner_image {
    width: 100%;
    border-radius: rem(16);
    background-color: rgba(255, 255, 255, 0.54);
    position: relative;
    @include breakpoint-min(lg) {
        padding-bottom: 100%;
    }

    .banner_inner {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include breakpoint-min(lg) {
            position: absolute;
        }

        .banner_img {
            border-radius: rem(16);
            height: 100%;
            width: 100%;
            @include breakpoint-max(lg) {
                height: 311px;
            }
        }
    }
}
