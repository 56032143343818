@use '../../../../../../../scss/abstracts' as *;
@use '../../../../../../../scss/layout/spacing' as *;
@use '../../../../../../../scss/theme/borders' as *;
@use '../../../../../../../scss/theme/colors' as *;

.checkout-bottom {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: $text-spacer-sm;
	.--cbreakdown {
		display: flex;
		flex-direction: column;
		gap: rem(16);
	}
	@include breakpoint-max(md) {
		margin-top: 0;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		border-radius: $border-radius $border-radius 0 0;
		padding: rem(16) rem(24);
		background-color: $white;
		box-shadow: 0px 4px 64px 0px #0000001C;
		border: border();
		z-index: 10;
		gap: rem(8);
	}
}
