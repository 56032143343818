@use '../../../../scss/abstracts' as *;
@use '../../../../scss/layout' as *;

#invoice-table {
	th {
		&:nth-of-type(2) {
			min-width: 526px;
		}
	}
}
