@use "../../../scss/abstracts" as *;
@use "../../../scss/layout" as *;
@use "../../../scss/theme/colors" as *;

.card-overlay {
    visibility: visible;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.8s;
    background: rgb(0, 0, 0);
    pointer-events: none;
    // Higher than nav, apple has differently but imo this is better
    z-index: 997;
}
.card-overlay.fade-in {
    visibility: visible;
    transition-timing-function: ease-out;
    pointer-events: all;
    opacity: 0.8;
}
.z-index-998 {
    z-index: 998;
    transition-duration: 0;
}
.z-index-neutral {
    z-index: 1;
    transition: z-index 0.8s step-end;
}
.feature-cards {
    &.spacer {
        padding-top: $spacer-md;
        padding-bottom: $text-spacer-xs;
        @include breakpoint-max(md) {
            padding-top: $text-spacer;
        }
    }

    .col-lg-6,
    .col-12 {
        margin: $text-spacer-xs 0px;

        @include breakpoint-max(lg) {
            padding-right: 0px;
            padding-left: 0px;
        }
    }
}
