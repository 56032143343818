@use '../../../../scss/abstracts' as *;

.abclineup {
	.lineup_container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: rem(16);
		.performer {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: rem(8);
			overflow: hidden;
			img {
				height: 40px;
				width: 40px;
				border-radius: 50%;
			}
		}
	}
}
