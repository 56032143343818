@use '../../../../../../../../scss/abstracts' as *;
@use '../../../../../../../../scss/layout/container' as *;
@use '../../../../../../../../scss/layout/spacing' as *;
@use '../../../../../../../../scss/theme/borders' as *;
@use '../../../../../../../../scss/theme/colors' as *;
@use '../../../../../../../../scss/theme/input' as *;

.purchaseTooltip {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: rem(12);
	gap: $gap-sm;
	width: 117px;
	min-height: 48px;
	background: #23262f;
	box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.11);
	border-radius: $br-md;
	pointer-events: none;
	touch-action: none;
	margin-right: 0;
	margin-left: 0;
	border: none;
	p {
		text-align: center;
		font-weight: 500;
		font-size: $small;
		line-height: 24px;
		color: $white;
		margin: 0;
	}
}

.vjx-tooltipWrapper {
	position: absolute;
	isolation: isolate;
	z-index: 11;
	border: none;
	padding: 0;
	margin-left: 0;
	margin-right: 0;
	background-color: transparent;
}
.htmlTooltip {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 200px;
	min-height: 116px;
	background: #23262f;
	box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.11);
	border-radius: $br-md;
	pointer-events: all;
	.htmlTop {
		width: 100%;
		height: 32px;
		background-color: #353945;
		border-radius: $br-md $br-md 0px 0px;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: $caption;
		line-height: 20px;
		font-weight: 400;
	}
	.htmlBody {
		flex-grow: 1;
		padding: rem(8) rem(12);
		padding-top: 0px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
	}
	.htmlPrice {
		color: #ffffff;
		font-weight: $fw-semi-bold;
		font-size: $body-2;
		line-height: 24px;
		margin-bottom: 4px;
		margin-top: 4px;
	}
	.htmlRow {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		.htmlSection {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			height: 100%;
			width: 55px;
			.htmlPh {
				color: $gray-200;
				font-weight: $fw-normal;
				font-size: $caption;
				line-height: 20px;
			}
			.htmlP {
				color: $white;
				font-weight: $fw-semi-bold;
				font-size: $small;
				line-height: 24px;
				height: 24px;
			}
		}
	}
	.htmlButton {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: rem(12) rem(16);
		gap: 12px;
		width: 100%;
		height: 40px;
		background-color: #3ea9f7;
		border: none;
		outline: none;
		font-size: $small;
		font-weight: $fw-bold;
		margin-bottom: rem(5);
		margin-top: rem(8);
		pointer-events: all;
	}
}

.GATooltip {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 260px;
	background: #23262f;
	box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.11);
	border-radius: $br-md;	
	pointer-events: all;
	.GATop {
		width: 100%;
		height: 32px;
		background-color: #353945;
		border-radius: $br-md $br-md 0px 0px;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fcfcfd;
		font-size: $small;
		font-weight: $fw-normal;
	}
	.GABody {
		flex-grow: 1;
		padding: rem(16) rem(12);
		padding-top: 0px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		gap: rem(8);
	}
	.GADesc {
		height: 40px;
		margin-top: rem(8);
		display: flex;
		justify-content: center;
		align-items: center;
		p {
			text-align: center;
			font-size: $body-2;
			color: #ffffff;
			font-weight: $fw-semi-bold;
			// Multiline overflow ellipsis
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
	.LRow {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		touch-action: auto;
		pointer-events: all;
		.LRowL {
			display: flex;
			flex-direction: row;
			gap: rem(8);
			.LRowS {
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
				text-align: left;
				color: #FCFCFD;
			}
		}
		.LRowB {
			padding: 8px 12px 8px 12px;
			border-radius: 8px;
			border: none;
			background-color: #353945;
			color: #FCFCFD;
			font-size: 12px;
			font-weight: 400;
			line-height: 20px;

			&:hover {
				background-color: #004cfc;
			}
		}
	}
	.GARow {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		.GAVSection {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			height: 100%;
			width: 113px;
			.GAVH {
				font-size: $caption;
				color: #FCFCFD;
				font-weight: $fw-normal;
				line-height: 20px;
			}
			.GAVP {
				font-size: $caption;
				color: $white;
				font-weight: $fw-semi-bold;
				line-height: 20px;
			}
			.GAVL {
				font-size: 10px;
				color: #777e91;
				font-weight: $fw-normal;
				line-height: 20px;
			}
		}
		.GARSection {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: $gap-xs;
			height: 100%;
			width: 136px;
			.GARV {
				width: 40px;
				height: 44px;
				border-radius: $border-radius-sm;
				border: 2px solid #353945;
				background-color: #353945;
				display: flex;
				justify-content: center;
				align-items: center;
				color: white;
			}
			.GARB {
				width: 44px;
				height: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #ffffff;
				font-size: 24px;
			}
		}
	}
	.GABRow {
		margin-top: rem(8);
		gap: $gap-sm;
		height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		.GAButton {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: rem(12) rem(16);
			gap: 12px;
			width: 148px;
			height: 40px;
			background-color: #3ea9f7;
			border: none;
			outline: none;
			font-size: $small;
			font-weight: $fw-bold;
			margin-top: 0;
		}
		.GABT {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 2px;
			.CountP {
				min-width: 10px;
			}
			p {
				font-size: $small;
				color: #ffffff;
				line-height: 24px;
				font-weight: $fw-medium;
			}
		}
	}
}

// Little rusty on my scss, be my guest w this one :)
.seatmapTooltipArrow {
	pointer-events: none;
	touch-action: none;
	&::after {
	  content: " ";
	  position: absolute;
	  border-style: solid;
	  border-width: 15px;
	  pointer-events: none;
	}
  
	// Top positioning
	&[class*="top--"]::after {
	  border-color: #23262f transparent transparent transparent;
	  top: 100%;
	}
  
	&.top--center::after {
	  left: 50%;
	  transform: translateX(-50%);
	}
  
	// Bottom positioning
	&[class*="bottom--"]::after {
	  border-color: transparent transparent #353945 transparent;
	  bottom: 100%;
	}
  
	&.bottom--center::after {
	  left: 50%;
	  transform: translateX(-50%);
	}
  
	// Left positioning
	&[class*="left--"]::after {
	  border-color: transparent transparent transparent #23262f;
	  left: 100%;
	}
  
	&.left--center::after {
	  top: 50%;
	  transform: translateY(-50%);
	}
  
	// Right positioning
	&[class*="right--"]::after {
	  border-color: transparent #23262f transparent transparent;
	  right: 100%;
	}
  
	&.right--center::after {
	  top: 50%;
	  transform: translateY(-50%);
	}
  
}