@use "../../../scss/abstracts" as *;
@use "../../../scss/layout/typography" as *;
@use "../../../scss/layout/spacing" as *;
@use "../../../scss/theme/colors" as *;

.checkout-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .csw-bottom {
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .success-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        padding: 24px;
        width: 100%;
        max-width: 864px;

        @include breakpoint-max(md) {
            grid-template-columns: 1fr;
            gap: 0px;
        }
    }

    .grid-item {
        &:nth-child(1) {
            grid-column: 1 / -1;
        }

        &:nth-child(2) {
            grid-column: 1 / -1;
        }

        &:nth-child(3),
        &:nth-child(4) {
            grid-column: span 1;
        }
    }

    .success-header {
        display: flex;
        align-items: center;
        .card-title-sm {
            padding-left: 8px;
            margin: 0;
            line-height: 32px;
            color: #000000;

            @include breakpoint-min(md) {
                font-size: $body-1;
            }
        }
    }

    .additional-info {
        .info-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 16px;

            svg {
                margin-right: 12px;
                flex-shrink: 0;
            }

            h4 {
                margin: 0 0 4px 0;
            }

            p {
                margin: 0;
                font-size: 0.9em;
                color: #555;
            }
        }
        @include breakpoint-max(md) {
            grid-row: 4;
        }
    }

    .order-summary {
        .card-title-sm {
            margin-top: 0;
            margin-bottom: 20px;
            line-height: 24px;
            color: #141416;
        }

        .summary-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            .si-t {
                @extend .small;
                line-height: 23.8px;
                text-align: left;
            }
            .si-v {
                @extend .small;
                line-height: 23.8px;
                text-align: right;
                color: #777e90;
            }
        }
    }

    @include breakpoint-max(md) {
        padding-bottom: 120px;
        .csw-bottom {
            display: flex;
            border-top: 1px solid rgb(235, 235, 235);
            background-color: white;
            padding: 24px 20px;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 999;
            .btn {
                width: 100%;
            }
        }
        .buying-this-container {
            padding-bottom: 0;
        }
    }
}
