@use "../../../scss/abstracts" as *;
@use "../../../scss/layout" as *;

#venue {
    .event-name-date-wrapper {
        @include breakpoint-min(lg) {
            width: 50%;
        }

        .event-name {
            @extend %text-truncate;
            @extend .normal !optional;
            @extend .normal-bold !optional;
            margin-bottom: rem(6);
        }
    }

    .event-image {
        width: 100px;
        height: 100px;
    }

    ul {
        .item {
            & + .item {
                margin-top: rem(16);
            }

            .event-info {
                min-width: 0;
                flex: 1;
            }

            .icon-button {
                @include breakpoint-max(lg) {
                    align-self: flex-start;
                }
            }
        }
    }
}
