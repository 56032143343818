@use "./spacing" as *;
@use "../abstracts" as *;

$pad-sm: rem(24);
$pad-md: rem(81);

$navbar-height-sm: rem(64);
$navbar-height: rem(83);

.container {
    max-width: 1130px;
    margin: 0 auto;
    @include breakpoint-max(md) {
        padding: 0px $pad-sm;
    }

    @include breakpoint-inBetween(md, xl) {
        padding: 0px $pad-md;
    }

    &--full-height {
        flex: 1;
    }
}

// view package page container
.lg-container {
    max-width: 1352px;
}

.info-container {
    @include breakpoint-max(md) {
        padding-right: $text-spacer-xs;
        padding-left: $text-spacer-xs;
    }
    @extend .container !optional;

    @media screen and (min-width: 670px) and (max-width: 768px) {
        padding: 0 60px;
    }

    // 928 + 12 + 12 (padding on each side of card grid)
    @include breakpoint-min(xxl) {
        max-width: 952px;
        margin: 0px auto;
    }
}

.docs-container {
    max-width: 672px;
    margin: 0px auto;
}

body {
    overscroll-behavior-y: none; // removes overscrolling (rubberbanding effect) on desktop and mobile
}

// on body and html
.full-height {
    height: 100%;
}

// on the root element
.full-height-flex {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.full-height-wrapper {
    @extend .full-height-flex !optional;
}

// bootstrap adds padding to body and margin to toggler on mmobile when modals open
.modal-open {
    padding: 0px !important;

    .navbar-toggler {
        margin-right: 0px !important;
    }
}

@mixin full-width() {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin sticky() {
    position: sticky;
    bottom: 0;
    z-index: 999;
    margin-top: auto;
}

.sticky {
    @include sticky();
}

// ticket modal -> my events
.sticky-mobile {
    @include breakpoint-max(md) {
        @include sticky();
    }
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

%icon-text-box {
    display: flex;
    gap: $gap;
    align-items: center;

    &::before,
    &::after {
        line-height: 0;
    }
}

// global icon sizes
%icon-size {
    &-sm {
        width: rem(16);
    }
    &-md {
        width: rem(24);
    }
    &-lg {
        width: rem(32);
    }
}

// remove container padding on mobile only
.mobile-p0 {
    @include breakpoint-max(md) {
        padding: 0px !important;
    }
}

.mobile-only {
    @include breakpoint-min(md) {
        display: none;
    }
}

.tablet-only {
    display: none;

    @media (min-width: 768px) and (max-width: 991px) {
        display: flex;
    }
}

.mobile-tablet-only {
    @include breakpoint-min(lg) {
        display: none;
    }
}

.tablet-desktop-only {
    @include breakpoint-max(md) {
        display: none;
    }
}

.desktop-only {
    @include breakpoint-max(lg) {
        display: none;
    }
}

@mixin remove-scrollbar() {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}

@mixin scrollable() {
    overflow-y: auto;
    height: 100%;
    flex: 1 1;
    min-height: 0;
    flex-basis: 0px;
    @include remove-scrollbar();
}

// ticket listings, filter menu -> ticket purchase flow
// package details
.scrollable-area {
    @include scrollable();

    &:not(.card-body) {
        flex-basis: 0; // starting height of 0px so does not stretch beyond the available space
    }
}

.event-image {
    aspect-ratio: 1/1;
}

// browsers that do not support aspect-ratio like Explorer and Safari
@supports not (aspect-ratio: 1/1) {
    /* defined that the height of the depends on its width */
    .image-wrapper {
        position: relative;
        padding-top: 100%; // aspect-ratio 1 / 1
        /* image is absolutely positioned and it has the full width and height of its parent, with object-fit: cover for cases of uploading a different-sized image.  */
        .event-image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
