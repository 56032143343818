@use '../../../../../../../../../scss/abstracts' as *;
@use '../../../../../../../../../scss/layout/spacing' as *;
@use '../../../../../../../../../scss/theme/input' as *;
@use '../../../../../../../../../scss/theme/borders' as *;
@use '../../../../../../../../../scss/theme/colors' as *;

#legend {
	&-container {
		width: 155px;
		border: border();
		padding: $input-padding-y $input-padding-x;
		background: $white;
		border-radius: $border-radius;
		display: flex;
		flex-direction: column;
		font-size: $small;
		.btn {
			line-height: 24px;
			color: #000000;
		}
	}

	&-list {
		display: flex;
		flex-direction: column;
		gap: $gap-sm;
		padding-top: 8px;
		// padding-right: $text-spacer-xs;

		.list-item {
			display: flex;
			gap: $gap-xs;
			align-items: center;
			line-height: 24px;

			&::before {
				content: '';
				width: 12px;
				height: 12px;
				border-radius: $br-xs;
			}

			&.unavailable {
				&::before {
					background-color: $gray-400;
				}
			}

			&.available {
				&::before {
					background-color: $primary;
				}
			}

			&.accessible {
				&::before {
					background-color: orange;
				}
			}

			&.resale {
				&::before {
					background-color: $clr-tertiary;
				}
			}
		}
	}
}
