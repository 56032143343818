@use '../abstracts' as *;

.rc-slider {
	&-handle {
		background-color: $clr-white;
		border: 4px solid $clr-primary;
		opacity: 1;
		margin-top: rem(-10);
		width: 24px;
		height: 24px;

		&:hover,
		&:focus,
		&:active,
		&-dragging {
			box-shadow: none !important;
			// border-color: $clr-white !important;
		}
	}

	&-rail {
		background-color: $clr-neutral-6;
		border-radius: 10px;
	}

	&-track {
		background-color: $clr-primary;
	}
}
