@use "../../../scss/abstracts" as *;
@use "../../../scss/layout" as *;
@use "../../../scss/theme/colors" as *;

.hero {
    padding-top: rem(35);
    // @extend %img-background;
    // // background-image: url('../../../assets/bg.svg');
    // background-position: top;
    background-color: #fff;
    text-align: center;
    @include full-width();

    @include breakpoint-min(md) {
        padding-top: rem(100);
    }

    @include breakpoint-max(md) {
        background-size: 206%;
        background-position: center 3%;
        text-align: left;
    }

    .hero-content {
        gap: $gap;
        margin-bottom: $text-spacer-sm;

        @include breakpoint-min(md) {
            height: 75vh;
            margin-bottom: $text-spacer-sm;
        }

        .hero-display {
            @include breakpoint-max(md) {
                padding: 0px $pad-sm;
            }
        }

        .arrow {
            margin: $text-spacer 0px;
            align-self: center;
            display: none;

            @include breakpoint-min(md) {
                margin-top: auto;
                display: block;
            }
        }
    }

    &.spacer {
        padding-bottom: 0px;
    }

    .hero-text-container {
        background: linear-gradient(180deg, #ffffff 0%, #f4f5f6 100%);
        @include full-width();

        .hero-text {
            padding-top: $text-spacer;
            padding-bottom: $text-spacer;

            @include breakpoint-min(md) {
                padding-top: rem(64);
                padding-bottom: rem(64);
            }
            @include breakpoint-max(md) {
                padding: 0px $pad-sm;
            }

            h4 {
                font-size: rem(36);

                margin: auto;
                @include breakpoint-min(md) {
                    width: 30ch;
                }

                @include breakpoint-max(md) {
                    font-size: $body-1;
                }
            }

            p {
                margin: auto;
                @include breakpoint-min(md) {
                    width: 44ch;
                }
            }
        }
    }

    .badge {
        order: -1;
        margin-bottom: $text-spacer-sm;

        @include breakpoint-min(md) {
            margin-left: auto;
            margin-right: auto;
            width: 315px;
        }
    }
}
