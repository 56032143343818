@use '../../../../../scss/abstracts' as *;
@use '../../../../../scss/layout/typography' as *;
@use '../../../../../scss/layout/spacing' as *;
@use '../../../../../scss/theme' as *;

.checkout-payment {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	font-family: $font-body;

	h4 {
		@extend .normal;
		@extend .normal-bold;
		line-height: 24px;
		margin-bottom: rem(4);
	}

	.card-form {
		width: 100%;
	}
}
