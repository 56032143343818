/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Set core root defaults */
body {
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: antialiased;
}

html:focus-within {
	scroll-behavior: smooth;
}

.display-1,
.display-4 {
	line-height: 1.2;
}

p {
	margin: 0;
}

a {
	font: inherit;
	text-decoration: none;
	word-wrap: break-word;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select,
legend {
	font: inherit;
}

ul,
ol,
dl {
	list-style: none;
	margin-bottom: 0;
	padding: 0;
}

figure {
	margin: 0;
}

img,
svg {
	max-width: 100%;
	height: auto;
	display: block;
	object-fit: cover;
}
