
.vjx-updating-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
    outline: none;
    background-color: #FCFCFD;
    box-shadow: 0px 64px 64px -48px #1F2F461F;
    border-radius: 20px;
    padding: 32px;
    width: 100%;
    max-width: 448px;

    &::backdrop {
		background: rgba(0, 0, 0, 0.3);
	}
    .updating-modal-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 16px;

        h4 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: #23262F;
            margin-bottom: 0;

        }   
        p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #23262F;
        }
    }
}