.vjx-detailsbutton {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    border-radius: 32px;
    border: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 3px 5px !important;
}
