@use '../../../../../../../../scss/abstracts' as *;
@use '../../../../../../../../scss/layout/container' as *;
@use '../../../../../../../../scss/layout/spacing' as *;
@use '../../../../../../../../scss/theme/borders' as *;
@use '../../../../../../../../scss/theme/colors' as *;
@use '../../../../../../../../scss/theme/input' as *;

.mobile-map-sheet-dialog {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    margin: 0;
}
#map {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #F4F5F6;
    overflow: hidden;
    transition: all 0.2s ease;
    padding: em(24);
    padding-bottom: em(65);
    position: relative;
    .expand {
        display: none !important;
    }

    &.cursor-pointer {
        cursor: pointer !important;
    }
    .map_svg {
        width: 100%;
        height: 100%;
        background-position-x: center;
        background-position-y: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .path-text {
        pointer-events: none;
    }
    .pathInvisible {
        opacity: 0;
        pointer-events: none;
    }
    .seat {
        transition: fill 0.5s ease 0s, stroke 0.5s ease 0s;
        fill-rule: evenodd;
        // fill: blue; // $gray-400
        z-index: 0;
        transition: filter 0.2s ease-in-out;
        cursor: pointer;
        .active {
            fill: green;
        }
        .resale {
            fill: $clr-tertiary;
        }
        .available {
            fill: $secondary;
        }
        .accessible {
            fill: orange;
        }
        .unavailable {
            fill: $gray-400;
        }
        &.--hover {
            &:hover {
                filter: brightness(80%);
            }
        }

        &.highlight {
            outline: 12px solid blue;
            outline-offset: 10px;
            border-radius: 12px;
        }
    }
    .path-onsale {
        transition: filter 0.2s ease-in-out;
        &:hover {
            filter: brightness(80%);
        }
    }
    .cursor_auto {
        cursor: auto;
    }

    .react-transform-component {
        width: 100% !important;
        height: 100% !important;
    }

    .react-transform-wrapper {
        overflow: visible !important;
        margin-left: auto;
        margin-right: auto;
        height: 100% !important;
        width: 100%;
    }

    .controls-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        pointer-events: none;
        

        .vjx-cc-outer {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            .vjx-legend {
                pointer-events: all;
                margin-left: 16px;
                margin-bottom: 16px;
            }
            
    
            .vjx-cc-inner {
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
    
                .zoomContainer {
                    pointer-events: all;
                    margin-right: 16px;
                    margin-bottom: 16px;
                }
                .mapsheet {
                    pointer-events: all;
                }
            }
        }
        .mobile-only {
            pointer-events: all;
        }
    }
}

@include breakpoint-max(md) {
    #map {
        .expand {
            z-index: 100;
            position: absolute;
            top: 16px;
            right: 16px;
            width: 40px;
            height: 40px;
            border: none;
            outline: none;
            background: rgba(255, 255, 255, 0.63);
            backdrop-filter: blur(2px);
            display: flex !important;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            padding: 0;
            margin-top: 0;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
