@use '../../../../scss/abstracts' as *;

.--tslider {
	position: relative;
	.swiper {
		.swiper-slide {
			.vjx-swiper-img-wrap {
				width: 100%;
				border-radius: 8px;
				background-color: #F4F5F6;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				img {
					min-width: 100%;
					min-height: 100%;
					user-select: none;
					object-fit: cover;
				}
			}
		}
	}
	.button-box {
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
		display: flex;
		justify-content: space-between;
		.btn--icon {
			padding: rem(16);
			background-color: rgba(255, 255, 255, 0.8);
			height: auto;
			width: auto;
			border: none;
			img {
				height: 24px;
				width: 24px;
			}
			&.--left {
				border-radius: 0px 16px 16px 0px;
			}
			&.--right {
				border-radius: 16px 0px 0px 16px;
				img {
					transform: rotate(180deg);
				}
			}
		}

		&.--smallbuttons {
			.btn--icon {
				padding: rem(4);
			}
		}
	}
}