@use '../../scss/abstracts' as *;
@use '../../scss/layout' as *;
@use '../../scss/theme' as *;

#settings-container {
	@include breakpoint-min(md) {
		gap: 65px;
	}
}

.settings-heading {
	margin-bottom: $text-spacer-sm;
}

.settings-title {
	font-size: $body-1;
}

.settings-subtitle {
	font-size: $small;
	color: $text-muted;
	line-height: $line-height-base;
	font-weight: $fw-normal;
	margin: 0px;
}
