@use "../../../scss/abstracts" as *;

.new-tickets-page {
    padding-top: rem(32);
    padding-bottom: rem(72);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: rem(32);
    .sticky-content {
        width: 33.33%;
        position: sticky;
        top: 116px;
        display: flex;
        flex-direction: column;
        gap: rem(24);
    }
    .tickets_col {
        gap: rem(24);
        display: flex;
        flex-direction: column;
        width: 66.66%;
    }

    @include breakpoint-max(lg) {
        padding-top: rem(16);
        flex-direction: column;
        gap: rem(16);
        padding-bottom: rem(81);
        .sticky-content {
            position: relative;
            top: unset;
            width: 100%;
        }
        .tickets_col {
            width: 100%;
            gap: rem(16);
        }
    }
}
