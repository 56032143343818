@use "../../scss/abstracts" as *;
@use "../../scss/layout/typography" as *;
@use "../../scss/layout/spacing" as *;
@use "../../scss/theme/colors" as *;

// You can put all this into some reusable file
.container {
    padding: 0px $text-spacer-xs !important;
}

.purchaseflow-gradient {
    @extend %background-gradient;

    // checkout, checkout success page
    &--tablet-desktop-only {
        @include breakpoint-max(md) {
            &::before {
                background: white;
            }
        }
    }

    .btn-back {
        position: absolute;
        top: $text-spacer;
        left: 0;
    }

    .great-wrapper {
        @include breakpoint-min(md) {
            margin-top: rem(24);
        }

        @include breakpoint-max(md) {
            border: none;
            background-color: transparent;

            .wrapper-body {
                padding: rem(20);
                padding-top: rem(16);

                .card-body {
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }
        }
    }
}

.py-16 {
    padding-top: $text-spacer-xs;
    padding-bottom: $text-spacer-xs;
}

.min-w-max-content {
    min-width: max-content;
}

.pfselect-wrapper {
    width: 100%;
    max-width: 732px;
    margin-left: auto;
    margin-right: auto;
    flex: 1;

    @include breakpoint-max(lg) {
        max-width: 100%;
    }

    .card {
        + .card {
            margin-top: $text-spacer-xs;
        }
    }

    .ticket-info {
        font-family: $font-body;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        color: $body-color;

        div > *:not(.badge) {
            line-height: 24px;
        }

        .col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 1;
        }

        .right-side {
            align-self: center;
        }
    }
}
