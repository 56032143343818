@use "../abstracts" as *;
@use "./container" as *;
@use "./spacing" as *;
@use "./images" as *;
@use "../theme/input" as *;
@use "../theme/colors" as *;
@use "../theme/borders" as *;
@use "../theme/buttons" as *;

%btn-background {
    @extend %img-background !optional;
}

.btn {
    &:active {
        border-color: transparent !important;
    }
}

.btn + .btn {
    margin-left: rem(16);
}

.btn-group-flex {
    align-items: center;
    gap: $gap;
    margin-top: $text-spacer-xs;
    > .btn {
        margin: 0px;
        flex: 1;
        width: 100%;
    }
}

.btn {
    margin-top: rem(14);
    white-space: nowrap;

    &.d-flex {
        flex-wrap: nowrap;
    }
    &-lg {
        height: 48px;
    }
    &-width {
        // unlock offer, presale button
        &-sm {
            min-width: 132px;
        }
        // sold out event button
        &-md {
            min-width: 173px;
        }
    }

    a {
        color: inherit;
    }
}

button,
.btn {
    &:focus {
        box-shadow: none !important;
    }
}

.btn-primary,
.btn-secondary,
.btn-success {
    color: $white;

    &:hover,
    &:focus,
    &:disabled {
        color: $white !important;
    }
}

.btn-outline-light {
    color: $body-color;

    &:disabled {
        color: inherit;
    }
}

// display tickets in ticket modals and ticket quantity - get tickets page
.btn-group {
    $active-state-background: $primary;
    $active-state-color: $white;

    display: flex;
    border-radius: 0px;

    // display tickets
    &.tickets-btn-group {
        column-gap: rem(18);

        @media (max-width: 375px) {
            column-gap: rem(5);
        }
    }

    > .btn:not(:first-child) {
        margin-left: 0px;
    }

    .btn-check {
        & + .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: $fw-medium;
            font-family: $font-body;
            border-radius: $border-radius-sm !important;
            border: border(2px);
            padding: $btn-padding-y-lg $btn-padding-x-lg;
            background-color: $gray-300;
            color: #000;

            // active - ticket quantity
            &.active {
                background-color: $active-state-background;
                border-color: $active-state-background;
                color: $active-state-color;
            }

            &:not(.ticket-pick) {
                flex-grow: 0;
            }
        }

        &:checked,
        &:focus {
            & + .btn {
                box-shadow: none;
            }
        }

        // checked - ticket quantity and ticket display
        &:checked {
            & + .btn {
                background-color: $active-state-background;
                border-color: $active-state-background;
                color: $active-state-color;

                .label {
                    color: $active-state-color;
                }
            }
        }
    }

    // display tickets in ticket modals
    .ticket-pick {
        font-family: $font-body;

        &.btn {
            border-radius: $border-radius !important;
            padding: rem(12) rem(16);
            border: none;
            font-size: $body-2;
            font-weight: $fw-semi-bold;
            flex: 0 0;
            flex-basis: 73.5px;
            height: 72px;
            margin-top: rem(14);
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: 500px) {
                flex-basis: 95px;
            }

            .label {
                color: $text-muted;
                font-size: $caption;
                font-weight: $fw-normal;
                line-height: 20px;
            }

            &:hover,
            &.selected {
                background-color: $active-state-background;
                color: $active-state-color;

                .label {
                    color: $active-state-color;
                }
            }

            &.selected {
                cursor: initial;
            }
        }
    }
}

.btn-link {
    border: 0;
    font-family: $font-body;
    font-weight: $fw-medium;
    padding: 0px;
    margin: 0px;
}

// filter menu -> ticket purchase flow
.btn-toggle {
    font-family: $font-body;
    font-weight: $fw-medium;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: $gap;

    &[aria-expanded="true"] {
        &::after {
            transform: rotate(180deg);
        }
    }

    &::after {
        width: 1.25em;
        line-height: 0;
        content: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM7 7L6.29289 7.70711C6.68342 8.09763 7.31658 8.09763 7.70711 7.70711L7 7ZM13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L13.7071 1.70711ZM0.292893 1.70711L6.29289 7.70711L7.70711 6.29289L1.70711 0.292893L0.292893 1.70711ZM7.70711 7.70711L13.7071 1.70711L12.2929 0.292893L6.29289 6.29289L7.70711 7.70711Z' fill='%236F767E'/%3E%3C/svg%3E%0A");
        transition: transform 0.35s ease;
        margin-left: auto;
    }
}

%btn-icon {
    padding: 0px;
    margin-top: 0px;
}

.btn--icon {
    @extend %btn-icon !optional;
    width: 40px;
    height: 40px;

    &-sm {
        @extend %btn-icon !optional;
        width: 32px;
        height: 32px;
    }
}

.btn--info {
    @extend %icon-text-box !optional;
    @extend .icon-button-md !optional;
    gap: $gap-sm;
    margin-top: 0px;
    white-space: nowrap;
    &::before {
        content: url($data-svg-prefix + map-get($icons, "info"));
    }
}

// ticket listings, filter menu -> ticket purchase flow
.btn--white {
    background-color: $white;
    border-radius: 16px !important;
    border: border($border-width, $light);
    padding: rem(8) rem(16);
    margin: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        min-width: rem(24);
    }

    &.active {
        background-color: $primary !important;
        border-color: $primary;
        color: $white;

        svg {
            filter: $white-filter;
        }
    }

    &.selected {
        background-color: rgba(62, 169, 247, 0.08) !important;
        outline: border($color: $primary);
        outline-offset: -1px;
        color: $secondary;

        svg {
            filter: $secondary-filter;
        }
    }
}

.icon-button {
    @extend %icon-text-box !optional;
    justify-content: center;

    &::before,
    &::after {
        line-height: 0;
    }

    &--danger {
        &::before,
        &::after {
            filter: $danger-filter;
        }
    }

    &--primary {
        &::before,
        &::after {
            filter: $primary-filter;
        }
    }

    &--secondary {
        &::before,
        &::after {
            filter: $secondary-filter;
        }
    }

    &--white {
        &::before,
        &::after {
            filter: $white-filter;
        }
    }

    &--black {
        &::before,
        &::after {
            filter: $black-filter;
        }
    }

    &-md {
        &::before,
        &::after {
            @extend %icon-size-md !optional;
            // info icons
        }
    }

    &-lg {
        &::before,
        &::after {
            @extend %icon-size-lg !optional;
            // alert info icons
        }
    }
}

.btn--icon {
    @extend .icon-button !optional;
}

// buttons that hold loading spinners - in ticket modals, settings
.btn-h {
    height: 48px;
    min-height: 48px;
    // accept transfer button
    &--sm {
        min-height: 38px;
    }
}

.btn-plus {
    @extend .icon-button !optional;
    gap: $gap-sm;
    &::before {
        content: url($data-svg-prefix + map-get($icons, "plus"));
    }
}

.btn-ticket {
    &--primary {
        @extend .icon-button--primary !optional;
        &::before {
            content: url($data-svg-prefix + map-get($icons, "ticket"));
        }
    }
}

.btn-unlock {
    @extend .icon-button !optional;
    gap: $gap-sm;
    &::before {
        content: url($data-svg-prefix + map-get($icons, "unlock"));
    }
}

.btn-notify {
    @extend .icon-button !optional;
    gap: $gap-sm;
    &::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_2918_20569)'%3E%3Cpath d='M14 12.0155C14 12.3409 13.7362 12.6047 13.4109 12.6047H2.58915C2.26377 12.6047 2 12.3409 2 12.0155C2 11.6901 2.26377 11.4264 2.58915 11.4264H2.6V7.32059C2.6 4.38192 5.018 2 8 2C10.982 2 13.4 4.38192 13.4 7.32059V11.4264H13.4109C13.7362 11.4264 14 11.6901 14 12.0155ZM3.8 11.4264H12.2V7.32059C12.2 5.03293 10.3196 3.17829 8 3.17829C5.6804 3.17829 3.8 5.03293 3.8 7.32059V11.4264ZM6.83101 14.1167C6.48109 13.6894 6.94772 13.1938 7.5 13.1938H8.5C9.05229 13.1938 9.51891 13.6894 9.16899 14.1167C9.13524 14.1579 9.0991 14.1975 9.06066 14.2353C8.77936 14.5115 8.39782 14.6667 8 14.6667C7.60218 14.6667 7.22064 14.5115 6.93934 14.2353C6.9009 14.1975 6.86476 14.1579 6.83101 14.1167Z' fill='%23FCFCFD'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2918_20569'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
}

.btn-next {
    @extend .icon-button !optional;
    &::after {
        content: url($data-svg-prefix + map-get($icons, "rightArrow"));
    }
}

.btn-directions {
    &::before {
        content: url($data-svg-prefix + map-get($icons, "location"));
    }
}

.btn-back {
    &::before {
        content: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.60612 0.843553C3.33557 0.593815 2.9138 0.610684 2.66406 0.88123L0.510216 3.21454C0.274484 3.46991 0.274482 3.86353 0.510212 4.11891L2.66406 6.45227C2.91379 6.72282 3.33557 6.73969 3.60611 6.48996C3.87666 6.24022 3.89354 5.81845 3.6438 5.5479L2.52273 4.33339L9.00008 4.33339C9.36827 4.33339 9.66675 4.03492 9.66675 3.66673C9.66675 3.29854 9.36827 3.00006 9.00008 3.00006L2.52275 3.00006L3.6438 1.78561C3.89353 1.51506 3.87666 1.09329 3.60612 0.843553Z' fill='%2323262F'/%3E%3C/svg%3E%0A");
    }
}

// venue card, event splash page
.btn-copy {
    // venue card
    &--success {
        @extend %img-background !optional;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.7071 0.328049C14.0976 0.718574 14.0976 1.35174 13.7071 1.74226L5.70711 9.74226C5.31658 10.1328 4.68342 10.1328 4.29289 9.74226L0.292893 5.74226C-0.0976311 5.35174 -0.0976311 4.71857 0.292893 4.32805C0.683417 3.93753 1.31658 3.93753 1.70711 4.32805L5 7.62094L12.2929 0.328049C12.6834 -0.0624748 13.3166 -0.0624748 13.7071 0.328049Z' fill='%2345B36B'/%3E%3C/svg%3E%0A");
    }
}
