@use "../abstracts" as *;
@use "../layout/typography" as *;
@use "../layout/container" as *;
@use "../theme/colors" as *;
@use "../theme/borders" as *;

.badge {
    line-height: 20px;
    $opac: 0.12;

    // ticket types
    &-primary {
        color: $secondary;
        background-color: rgba($secondary, $opac);
    }

    &-exclusive {
        color: #9757d7;
        background-color: rgba(151, 87, 215, 0.12);
    }

    &-resale {
        color: #f0641e;
        background-color: rgba(239, 126, 70, 0.12);
    }

    &-danger {
        color: $danger;
        background-color: rgba($danger, $opac);
    }

    &-gray {
        background-color: #f4f5f6;
        padding: 2px 4px;
        color: #000000;
    }

    // ticket purchase flow
    &-outline {
        border-style: solid;
        border-width: 1px;
        background-color: transparent !important;

        &--dark {
            color: $body-color !important;
            border-color: $gray-200;
        }
    }

    &-quickpick {
        padding-top: rem(2);
        padding-bottom: rem(2);
        white-space: normal;
        text-align: left;
    }

    // ticket card
    &-lg {
        @extend .normal !optional;
        @extend .normal-bold !optional;
        border-radius: $br-primary;
        padding: rem(12) rem(16);
        justify-content: center;
        align-items: center;
        height: 67px;
    }

    &--tickets {
        @extend %icon-text-box !optional;
        justify-content: center;
        border-radius: 40px;
        font-size: $small;
        padding: rem(9) rem(16);
        &::before {
            content: url($data-svg-prefix + map-get($icons, "ticketOutline"));
            width: 18px;
            height: 18px;
            transform: scale(0.9);
        }

        @include breakpoint-min(md) {
            padding: rem(9) rem(36);
        }
    }
    // feature card - homepage
    &--clock {
        @extend %icon-text-box !optional;
        gap: rem(8);
        justify-content: center;
        border-radius: $border-radius;
        padding: rem(8) rem(12);
        &::before {
            content: url($data-svg-prefix + map-get($icons, "clock"));
        }
    }
}
