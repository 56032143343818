@use "../../../../../../scss/abstracts" as *;
@use "../../../../../../scss/layout/typography" as *;
@use "../../../../../../scss/theme/colors" as *;
@use "../../../../../../scss/theme/borders" as *;

// Hide scroll when dialog is open
body:has(.dialog-confirmexitmap[open]) {
    overflow: hidden;
}
.dialog-confirmexitmap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 16px;
    padding: 32px;
    border: 2px solid #ffffff;
    background-color: #ffffff;
    width: 100%;
    max-width: 448px;

    .confirmexitmap-content {
        display: flex;
        flex-direction: column;
        h4 {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            text-align: left;
            color: #23262f;
            margin-bottom: 16px;
        }
        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #23262f;
        }
    }
}
