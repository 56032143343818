@use '../../scss/abstracts' as *;
@use '../../scss/layout/container' as *;
@use '../../scss/theme/colors' as *;

#page-loading-container {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: calc(100vh - $navbar-height-sm);

	@include breakpoint-min(lg) {
		height: calc(100vh - $navbar-height);
	}

	#spinner-icon {
		width: 48px;
		height: 48px;
	}
}
