@use "../abstracts" as *;
@use "./spacing" as *;
@use "./typography" as *;
@use "./container" as *;
@use "../theme/card" as *;
@use "../theme/colors" as *;

%plain-card {
    background: transparent;
    border-color: transparent;
    border-radius: 0px;

    .card-body {
        padding: em(21);

        & * {
            @extend %text-truncate !optional;
        }
    }

    .card-title {
        margin-bottom: em(10);
    }
}

.myCard,
.ticket-card {
    width: rem(256);
}

.card {
    &-subtitle {
        margin-top: 0px;
    }

    // feature card - homepage
    &--black {
        background-color: #000;
    }

    // white - get tickets flow
    // event details tickets, guest pass tickets
    &--white {
        background-color: #fff;
    }

    // feature cards
    &--dark-gray {
        background-color: #23262f;
    }

    // feature cards
    &--tertiary-light {
        background-color: #e9e2f2;

        &.frontY {
            .card-title {
                color: $clr-tertiary;
            }
        }
    }

    // off white - fcfcfd
    // feature cards - homepage
    // setting cards
    // venue card
    &--light {
        background-color: $white;

        .card-header,
        .card-footer {
            background-color: inherit;
        }
    }

    // e6e8ec
    // add ons - ARE USING ?
    // feature and secondary cards - homepage
    &--muted {
        background-color: $light;
    }

    // feature and secondary cards - homepage
    &--pale {
        background-color: #e4d7cf;
    }

    // off black - withdraw card
    &--dark {
        background-color: $clr-neutral-3;
    }

    // feature and secondary cards - homepage
    &--darker {
        background-color: #141416;
    }

    // feature cards - homepage
    &--secondary {
        background-color: $secondary;
    }

    &--secondary,
    &--black,
    &--dark,
    &--darker,
    &--dark-gray,
    &--primary {
        .card-title,
        * {
            color: $white;
        }
    }

    // ticket listing, checkout -> ticket purchase flow
    &-body-heading {
        margin-bottom: $text-spacer-xs;

        .card-title {
            margin-bottom: 0px;
        }

        &--flex {
            @extend %flex-between !optional;
            margin-bottom: $text-spacer-xs;

            > * {
                margin: 0px;
            }
        }
    }

    // ticket filter cards, checkout -> ticket purchase flow
    &--popup {
        @include breakpoint-max(md) {
            @include full-width();
            border-bottom-right-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            box-shadow: $box-shadow-popupCard;
        }
    }

    .event-details {
        margin-bottom: rem(16);
        .event-detail {
            margin-bottom: rem(10);
        }
    }

    // ticket purchase flow cards, setting cards, event details tickets, guest pass tickets
    &-title {
        @extend .fs-md !optional;
        @extend .fs-md-bold !optional;
        color: $body-color;

        // ticket purchase flow
        // event details tickets, guest pass tickets
        &-sm {
            font-size: $body-2;
        }

        // ticket purchase flow
        &--uppercase {
            @extend .text-uppercase !optional;
        }
    }

    &-subtitle {
        font-size: $small;
        font-weight: $fw-normal;

        // my packages card -> my events
        &-lg {
            font-size: $body-2;
        }

        &--light {
            color: $text-muted;
        }
    }

    &-date {
        border: border();
        border-radius: 12px;
        padding: rem(4) rem(12);
        flex-grow: 0;
        justify-content: center;
        align-items: center;

        .month {
            line-height: 20px;
            margin: 0px;
            color: $text-muted;
        }

        .day {
            line-height: 24px;
        }
    }

    // ticket list card - package events -> venue profile
    &-list {
        border: border(2px, #fff);
        background-color: rgba(255, 255, 255, 0.74);

        .card-body {
            padding: rem(12) !important;
            display: flex;
            align-items: center;
            gap: rem(16);
        }

        &--dark-border {
            border-color: $gray-300;
        }
    }

    // venue profile list cards
    &-xs {
        .card-body {
            padding: $text-spacer-xs;
        }
    }

    // used for secondary feature cards - homepage
    &-sm {
        border: none;
        border-radius: 21px;
        &.card-body {
            padding: em(32) rem(24);
        }

        .card-title {
            @extend .headline-4 !optional;
            font-weight: $fw-semi-bold;
        }
    }

    // used in settings options
    &-lg {
        $padding: em(30) em(20);

        .card-body {
            padding: $padding;
        }
    }

    // ticket purchase flow cards
    &-xl {
        border-radius: 16px;
        border: border($border-width, #fff);

        @include breakpoint-min(md) {
            border-radius: 20px;
        }

        .card-body {
            padding: rem(16);
            @include breakpoint-min(md) {
                padding: rem(24);
            }

            .card-title {
                &-sm {
                    @extend .card-title-sm !optional;
                }
            }
        }

        // dark grey
        &--dark {
            background-color: rgba(255, 255, 255, 0.54);
        }

        &--light {
            @extend .card--white !optional;
        }
    }

    // ticket purchase flow cards
    &-xl-no-mobile {
        margin-top: rem(0);
        .card-xl-container {
            padding: unset;
            padding-top: rem(16);
            flex: 1 1;
        }
        @include breakpoint-min(md) {
            border-radius: 20px;
            border: border($border-width, #fff);
            margin-top: rem(24);

            .card-xl-container {
                padding: rem(24);
            }

            // dark grey
            &--dark {
                background-color: rgba(255, 255, 255, 0.54);
            }
        }
    }

    // used on feature cards in info wrapper
    &-xxl {
        border-radius: 30px;
        border: none;

        &.card-body {
            padding: rem(64) rem(44);

            @include breakpoint-min(lg) {
                padding-left: rem(44);
                padding-right: rem(44);
            }
        }

        .card {
            &-title {
                font-size: rem(36);
                font-weight: $fw-semi-bold;
                margin-bottom: 0px;

                @include breakpoint-min(md) {
                    font-size: rem(42);
                }

                @include breakpoint-min(xxl) {
                    font-size: rem(54);
                }

                &-sm {
                    font-size: $h4-font-size !important;
                }
            }

            &-subtitle {
                color: $text-muted;
                @extend .headline-4 !optional;
                font-weight: $fw-semi-bold;
                margin-top: em(5);

                &-sm {
                    font-size: $body-1;
                    font-weight: $fw-medium;
                }

                @include breakpoint-max(md) {
                    font-size: rem(18);
                    margin-top: rem(15);
                }

                @include breakpoint-inBetween(md, xxl) {
                    font-size: $body-1;
                    margin-top: rem(15);
                }
            }

            &-text {
                color: $text-muted;

                @include breakpoint-max(md) {
                    font-size: $small;
                }
            }
        }
    }

    // logos for packages, venue card
    &-img-container {
        border: border();
        border-radius: 8px;
        background-color: #fff;
        align-self: start;

        .card-img {
            width: 160px;
            height: 160px;
            border-radius: inherit;
        }

        &-lg {
            border-radius: 40px;
        }

        &-sm {
            border-radius: 15px;
            border: border();

            .card-img {
                width: 108px;
                height: 108px;
            }
            @include breakpoint-max(md) {
                width: 100%;
                height: 167px;

                .card-img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
