@use '../../../scss/abstracts' as *;
@use '../../../scss/layout' as *;

.card.myCard {
	@extend %plain-card;

	.card {
		&-img-top {
			background-color: $clr-neutral-6;
			border-radius: $br-md;
		}

		&-body {
			display: flex;
			flex-direction: column;
			padding-left: 0px;
			padding-right: 0px;

			hr {
				margin: rem(12) 0px;
				background-color: $clr-neutral-4;
			}
		}

		&-text {
			font-size: $caption;
		}

		&-title {
			font-size: $body-2;
		}

		&-subtitle {
			font-family: $font-heading;
			font-size: $small;
		}
	}

	.row + .row,
	li + li {
		margin-top: rem(8);
	}
}
