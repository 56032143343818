.select_get_soldout {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	.card-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
}
