@use "../../../scss/abstracts" as *;
@use "../../../scss/layout/typography" as *;

#venue {
    .venue-card {
        &.card {
            margin-top: -65px;
            box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);

            @include breakpoint-min(md) {
                margin-top: -133px;
            }

            .card-body {
                padding: em(32);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .card-img-container {
                    background-color: #fff;
                    order: -3;
                    margin-bottom: rem(25);
                }

                .card-title {
                    font-weight: $fw-semi-bold;
                    order: -2;
                }

                .card-text {
                    order: -1;
                    line-height: 20px;
                    @include multi-line-truncate(5);
                    margin-bottom: rem(28);
                }

                .btn--icon {
                    flex-basis: 40px;
                }
            }
        }
    }
}
