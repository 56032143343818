@use './../../../scss/abstracts' as *;
@use '../../../scss/theme/colors' as *;

.search-dropdown {
	background-color: $white;
	box-shadow: rgb(0 0 0 / 16%) 0px 4px 16px;
	position: absolute;
	left: 0;
	right: 0;
	top: 43px;
	z-index: 999;
	border-radius: $br-primary;
	padding: rem(16) rem(24);
}
