

.vjy-yb {
    flex: 1 1 auto;
    text-align: center;

    .vjy-sc {
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: auto;

        .vjy-etl {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #777E90;
        }
    }
}