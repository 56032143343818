@use './../../../scss/abstracts' as *;
@use './../../../scss/theme/colors' as *;

#bank-account-card {
	box-shadow: $box-shadow;
	.card-text {
		color: $text-muted;
		font-size: $small;
	}
}
