@use "../../../../../../../scss/abstracts" as *;
@use "../../../../../../../scss/layout/typography" as *;
@use "../../../../../../../scss/layout/spacing" as *;
@use "../../../../../../../scss/theme/colors" as *;
@use "../../../../../../../scss/theme/borders" as *;

.vjx-ticket-inner {
    font-family: $font-body;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: start;
    width: 100%;
    gap: rem(16);
    border-radius: 16px;
    background-color: #ffffff;
    border: border();
    padding: rem(12);

    &:focus {
        border: 1px solid #5ab6f8;
        outline: none;
    }
    .img-wrap {
        position: relative;
        overflow: hidden;
        display: flex;
        padding: 0;
        border: none;
        outline: none;
        border-radius: $border-radius-sm;
        background-color: #f1f2f4;
        align-self: stretch;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        .ticket-view {
            width: 123px;
            height: 68px;
            border-radius: $border-radius-sm;
            object-fit: cover; // contain

            @include breakpoint-min(md) {
                height: 92px;
            }
        }
    }
    .img-wrap:hover {
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 24px;
            background: url("../../../../../../../assets/icons/fullscreen.svg") no-repeat center center;
            background-size: cover;
            z-index: 1;
        }
        filter: brightness(90%);
    }
    .ticket-info {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .ticket-info-c1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include breakpoint-max(md) {
                width: 100%;
            }
            .wrapper {
                @include breakpoint-min(md) {
                    gap: rem(8);
                }
            }

            .ticket-seat-quantity-wrapper {
                @include breakpoint-max(md) {
                    order: 2;
                    justify-content: space-between;
                    align-self: stretch;
                }
                @include breakpoint-min(md) {
                    gap: rem(5);
                }

                .ticket-seat {
                    font-size: $body-2;
                    font-weight: $fw-semi-bold;
                    line-height: 24px;
                    color: #23262f;
                    text-align: start;
                    @include breakpoint-max(md) {
                        font-size: $caption;
                        font-weight: $fw-normal;
                        line-height: 20px;

                        &::before {
                            display: none;
                        }
                    }
                }
                .ticket-quantity {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #141416;
                    margin: 0px;

                    @include breakpoint-max(md) {
                        line-height: 20px;
                    }
                    @include breakpoint-min(md) {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        .ticket-info-c2 {
            line-height: 24px;
            align-items: center;

            &.mobile-only {
                @include breakpoint-max(md) {
                    display: flex;
                    order: 1;
                    margin-top: rem(2);
                    gap: rem(8);
                }
            }

            &.tablet-desktop-only {
                @include breakpoint-min(md) {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    white-space: nowrap;
                }
            }

            .ticket-price {
                font-size: $body-2;
                font-weight: $fw-semi-bold;
                line-height: 24px;

                @include breakpoint-max(md) {
                    font-size: $small;
                    font-weight: $fw-medium;
                }
            }
            .ticket-fees {
                font-size: $caption;
                font-weight: $fw-normal;
                color: #777e90;
            }
        }
    }
}
