@use '../../scss/abstracts' as *;
@use '../../scss/theme/colors' as *;
@use '../../scss/layout/spacing' as *;
@use '../../scss/layout/typography' as *;
@use '../../scss/layout/container' as *;

.event {
	padding: rem(12) 0px;
	@include full-width();
	border-bottom: border();

	@include breakpoint-max(md) {
		background-color: $gray-300;
	}

	@include breakpoint-min(md) {
		padding: rem(18) 0px;
	}

	.event-image {
		&-wrapper {
			flex-shrink: 0;
		}

		& {
			border-radius: $br-xs;
		}
	}

	.details {
		font-weight: $fw-normal;
		flex-grow: 1;
		min-width: 0;
		font-size: $body-2;

		@include breakpoint-max(md) {
			> *:not(.event-name) {
				font-size: $small;
			}
		}

		.event-name {
			margin-bottom: rem(2);

			@include breakpoint-max(md) {
				margin-bottom: rem(5);
				font-size: $body-2;
			}
		}
	}

	#event-info {
		margin-left: auto;
		margin-right: 2px;
		@include breakpoint-min(md) {
			border-color: $light;
			color: $muted;

			svg {
				width: 16px;
			}
		}

		@include breakpoint-max(md) {
			padding: 0px;
		}
	}
}
