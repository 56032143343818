


.vjx-ticket-counter {
    gap: 4px;

    .btn--icon {
        border: none;
        border-radius: 8px;
        height: 44px;
    }

    .ticket-count {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 8px;
        width: 40px;
        height: 40px;
    }
    &.--dark {
        .btn--icon {
               svg {
                 fill: #fcfcfd;
                }
            &:disabled {
                svg {
                    fill: gray;
                }
            }
        }
        .ticket-count {
            color: #fcfcfd;
            background-color: #353945;
        }
    }
    &.--white {
        .btn--icon {
               svg {
                 fill: #000000;
                }
            &:disabled {
                svg {
                    fill: gray;
                }
            }
        }
        .ticket-count {
            color: #141416;
            background-color: #FCFCFD;
            border: 2px solid #E6E8EC;
        }
    }
}