@use './mixins' as *;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=Poppins:wght@400;500;600;700&family=Roboto:wght@500;700&display=swap');

$font-body: 'Poppins', sans-serif;
$font-heading: 'DM Sans', sans-serif;
$font-heading-alt: 'Roboto', sans-serif;

// Font Weights
$fw-normal: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;

$h1-font-size: rem(64);
$h2-font-size: rem(48);
$h3-font-size: rem(40);
$h4-font-size: rem(32);

$body-1: rem(24);
$body-2: rem(16);
$small: rem(14);
$caption: rem(12);
