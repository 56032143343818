@use "../../../../scss/abstracts" as *;
@use "../../../../scss/layout/spacing" as *;
@use "../../../../scss/layout/images" as *;
@use "../../../../scss/theme/colors" as *;
@use "../../../../scss/theme/borders" as border;

#countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap;
    padding: rem(24);
    background-color: rgba(255, 255, 255, 0.54);
    border-radius: 20px;
    border: 2px solid #fff;


    &.icon {
        &::before {
            content: "";
            @extend %img-background;
            background-image: url($data-svg-prefix + map-get($icons, "ticket"));
            background-color: rgba($primary, 0.1);
            background-size: 18px;
            width: 44px;
            height: 44px;
            border-radius: border.$border-radius-sm;
        }
    }

    #countdown-timer {
        padding-top: rem(12);
        font-size: $caption;
        width: 100%;

        .vstack {
            place-items: center;
            position: relative;
            flex: 0 0 81px;

            &:not(:last-child) {
                &::after {
                    content: ":";
                    position: absolute;
                    right: -4px;
                    top: 15%;
                    font-size: $body-1;
                    font-weight: $fw-bold;
                }
            }
        }

        .ticker {
            font-size: $h3-font-size;
            font-family: $font-heading;
            width: 100%;
            text-align: center;
        }
    }
}
