@use "../../../../scss/abstracts" as *;
@use "../../../../scss/layout/typography" as *;
@use "../../../../scss/layout/spacing" as *;
@use "../../../../scss/layout/buttons" as *;
@use "../../../../scss/theme/colors" as *;
@use "../../../../scss/layout" as *;
@use "../../../../scss/theme/input" as *;

.vjx-full {
    width: 100%;
    flex: 1 1;
}
.vjx-1 {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    margin-top: 24px;
    @include breakpoint-max(md) {
        margin-top: 0;
    }
}

.vjx-2 {
    width: 100%;
    max-width: 326px;
    min-width: 326px;
    padding: 24px;
    height: min-content;
}

.vjx-3 {
    gap: $text-spacer;

    @include breakpoint-max(md) {
        @include scrollable();
    }

    li {
        list-style: none;

        .filter-heading {
            display: block;
            @extend .small !optional;
            @extend .small-bold !optional;
            color: $text-muted;
            margin-bottom: rem(12);
        }

        &.offers-item {
            display: flex;
            flex-direction: column;
            height: 100%;
            @include breakpoint-min(md) {
                max-height: 36vh;
            }

            .form-check-label {
                font-size: 14px;
                font-weight: 500;
                color: #141416;
            }

            .accessibility {
                @extend %icon-text-box;
                gap: $gap-sm;
                &::before {
                    content: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.54203 3.64795C6.50403 3.64795 7.27803 2.86595 7.27803 1.92395C7.27803 0.967951 6.50403 0.199951 5.54203 0.199951C4.58403 0.199951 3.80603 0.967951 3.80603 1.92395C3.80603 2.86595 4.58403 3.64795 5.54203 3.64795ZM15.73 15.442L12.77 10.368C12.586 10.062 12.264 9.88995 11.93 9.87995H7.58603L7.54203 8.35395H10.696C11.126 8.32795 11.48 8.00395 11.48 7.56595C11.48 7.13395 11.136 6.79795 10.696 6.78395H7.45403L7.34203 5.52795C7.27803 4.62395 6.49403 3.91195 5.57403 3.96195C4.64603 4.01395 3.94603 4.80595 3.99003 5.72195L4.29603 10.954C4.37203 11.872 5.14403 12.522 6.06003 12.522H11.69L13.99 16.476C14.266 16.924 14.904 17.108 15.384 16.822C15.858 16.534 16 15.942 15.73 15.442ZM6.65803 18.444C3.81003 18.444 1.50003 16.156 1.50003 13.324C1.50003 11.774 2.20803 10.39 3.31003 9.44795L3.21403 7.84795C1.37003 8.99195 0.134033 11.002 0.134033 13.324C0.134033 16.9 3.05203 19.802 6.65803 19.802C9.31203 19.802 11.58 18.216 12.6 15.96L11.684 14.38C11.192 16.694 9.13603 18.444 6.65803 18.444Z' fill='%23777E91'/%3E%3C/svg%3E%0A");
                }
            }

            .offers-list {
                @include breakpoint-min(md) {
                    @include scrollable();
                }
            }

            .offer-desc {
                width: 100%;
                color: $text-muted;
                padding-left: rem(28.875);
                padding-bottom: rem(8);
                padding-top: rem(8);
                padding-right: rem(21.5);

                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 23.8px;
                    color: #777e90;
                }
            }
        }
    }
}

.vjx-no-results {
    margin-right: 24px;
    margin-left: 24px;
    background-color: rgba(252, 252, 253, 1);
    border-radius: 16px;
    padding: 24px;
    gap: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: rgba(20, 20, 22, 1);
    }

    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: rgba(119, 126, 144, 1);
    }
}

.vjx-4 {
    padding-top: 16px;
}

.vjx-5 {
    padding-top: 20px;
}

.vjx-8 {
    width: 205px;
    height: 95px;
    @include breakpoint-max(md) {
        width: 100%;
        height: 120px;
    }
}
.vjx-10 {
    width: 100%;
    height: 160px;
}
.vjx-lh {
    line-height: 24px;
}
.vjx-6 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $gray-400;
    border-radius: 16px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &--disabled {
        opacity: 0.5;
    }
    .button-mockup {
        padding: 12px 16px;
        background-color: #ffffff;
        border: 2px solid #e6e8ec;
        border-radius: 90px;
        gap: 12px;
        display: flex;
        flex-direction: row;
        width: auto;
        align-items: center;

        span {
            color: #23262f;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            font-family: "DM Sans", sans-serif;
        }

        svg {
            fill: #23262f;
        }
        &--disabled {
            span {
                color: $text-muted;
            }
            svg {
                fill: $muted;
            }
        }
    }
    &:hover {
        .button-mockup {
            &:not(.button-mockup--disabled) {
                background-color: #f4f4f4;
            }
        }
    }
    &:focus {
        .button-mockup {
            outline: 2px solid #ffffff;
            outline-offset: 2px;
        }
    }
}

.vjx-7 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nstc-ss {
    width: 100%;
    height: 100%;
    max-width: 880px;
    flex: 1 1;
}
.max-h-screen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    max-height: 100dvh;
    overflow: hidden;
    overscroll-behavior-y: contain;
}
.ticketpanel-height {
    // height: calc(100dvh - 60px);
    min-height: 0;
}
.cxb-24 {
    padding: 0 0 16px 16px;
}
.pxt-m {
    padding: 16px 16px 0 16px;
}
.pxb {
    overscroll-behavior-y: contain;
    padding: 0 16px 0 16px;
}
.pxbbb {
    padding: 0px 16px 0 16px;
}
.pxccc {
    padding: 16px 16px 0 16px;
}
.vjxab-pr {
    padding-right: 16px;
    padding-left: 16px;
}
@include breakpoint-min(md) {
    .pxccc {
        padding: 0 0 0 0;
    }
    .vjxab-pr {
        padding-right: 24px;
        padding-left: 24px;
    }
    .pxbbb {
        padding: 0px 24px 0 24px;
    }
    .pxb {
        padding: 0 24px 0 24px;
    }
    .pxt {
        padding: 24px 24px 0 24px;
    }
    .cxb-24 {
        padding: 0 24px 16px 24px;
    }
    .pxt-m {
        padding: 24px 24px 0 24px;
    }
    .ticketpanel-height {
        max-height: calc(100vh - 104px);
        min-height: calc(100vh - 104px);
    }
    .max-h-screen {
        max-height: unset;
        padding-top: $text-spacer;
    }
}
.flex-one {
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
}
.new-select-tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: $text-spacer;
    position: relative;
    @include breakpoint-max(md) {
        padding-top: $text-spacer-xs;
        &--filter-open {
            padding-top: 0px;
        }
    }
    @include breakpoint-min(md) {
        .collapse-inner {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    .great-wrapper {
        flex: 1;
        @include breakpoint-max(md) {
            &--filter-open {
                background-color: $light;
            }
        }

        .tablet-desktop-only {
            font-family: $font-body;
            color: #000000;
            h5 {
                @extend .fs-md;
                @extend .fs-md-bold;
                line-height: 32px;
                margin-bottom: 0;
            }
            p {
                font-size: $small;
                line-height: 24px;
            }
        }

        .card-body {
            display: flex;
            flex-direction: column;
        }
    }

    .tickets-col {
        display: flex;
        flex-direction: column;
        gap: rem(8);
    }

    .actions-bar {
        .dropdown-item {
            &.active {
                background-color: $gray-300;
                border: border($color: $light);
                color: $body-color;
            }
        }
    }
    .btn-group {
        // filter menu
        > .btn.btn-toggle {
            &--white {
                @extend .btn--white;
                &.num {
                    width: 41px;
                }
            }
            // filter cards
            &--no-borders {
                border-color: transparent;
                justify-content: flex-start;
                padding-left: rem(8);
                padding-right: rem(8);
            }
        }

        .btn-check {
            &:not(:checked) {
                + .btn,
                & + .btn:not(.selected) {
                    background-color: #fff;
                }
            }
        }
        &.greyScale {
            $active-state-background: $gray-300;
            $active-state-borderColor: $light;
            $active-state-color: $body-color;
            .btn-check {
                &:checked {
                    & + .btn {
                        background-color: $active-state-background;
                        border-color: $active-state-borderColor;
                        color: $active-state-color;
                    }
                }
            }

            .btn.active {
                background-color: $active-state-background;
                border-color: $active-state-borderColor;
                color: $active-state-color;
            }
        }
    }
}
