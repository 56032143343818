@use './../../scss/abstracts' as *;

#search-list {
	.cat-label {
		text-align: left;
	}

	#list {
		margin-left: rem(-8);
		margin-right: rem(-8);
	}
}
