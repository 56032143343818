@use '../../../../../../scss/abstracts' as *;
@use '../../../../../../scss/layout/typography' as *;
@use '../../../../../../scss/theme/colors' as *;
@use '../../../../../../scss/theme/borders' as *;

// Hide scroll when dialog is open
body:has(.dialog-sm-base[open]) {
	overflow: hidden;
}
.dialog-sm-base {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 16px;
	padding: rem(24);
	border: border($border-width, #ffffff);
	background-color: #ffffff;
	width: 100%;
    height: 100%;
    max-width: calc(100% - 2em - 6px);
    max-height: calc(100% - 2em - 6px);
    .dialog-sm-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        .sm-content {
            flex: 1 1;
            position: relative;
            border-radius: 16px;
            overflow: hidden;
            border: 1px solid #E6E8EC;
            display: flex;
            flex-direction: row;
        }
        &::backdrop {
            background: rgba(0, 0, 0, 0.3);
        }
    }

    // Make mobile fullscreen
    @include breakpoint-max(md) {
        max-width: 100%;
        max-height: 100%;
        padding: 0;
        border-radius: 0;
        border: none;
        .dialog-sm-inner {
            .sm-content {
                border-radius: 0;
                flex-direction: column;
            }
        }
    }
}
