@use "../../../scss/abstracts" as *;
@use "../../../scss/layout/spacing" as *;
@use "../../../scss/layout/container" as *;
@use "../../../scss/layout/typography" as *;
@use "../../../scss/theme/colors" as *;
@use "../../../scss/theme/borders" as *;

header {
    &.hero {
        padding: 10vh $text-spacer 16vh;
        background-image: url("../../../assets/bg.png");
        background-color: #0000;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include full-width();

        @include breakpoint-min(lg) {
            padding-top: 18vh;
            padding-bottom: 33vh;
        }

        .display-1,
        .subtitle {
            color: $white;
            text-align: center;
        }

        .display-1 {
            @extend .headline-h4 !optional;
            font-weight: $fw-semi-bold;
            margin-bottom: rem(8);
            line-height: 1.2;

            @include breakpoint-min(md) {
                font-size: $h2-font-size;
            }
        }

        .subtitle {
            @extend .small !optional;
            @extend .small-bold !optional;
            line-height: 24px;

            @include breakpoint-max(lg) {
                width: 35ch;
            }

            @include breakpoint-min(md) {
                font-size: $body-2;
            }
        }

        .hero-search {
            margin-top: rem(30);

            .search {
                flex: 1;
                padding-right: 0px;
                padding-left: rem(22);

                &:focus-within {
                    border-right: none;
                }

                @include breakpoint-min(md) {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                input {
                    padding-left: rem(17);
                }

                .search-icon {
                    order: -1;
                }
            }

            @include breakpoint-min(lg) {
                width: 863px;
                margin-left: auto;
                margin-right: auto;
            }

            .search-dropdown {
                top: 71px;
            }
        }
    }
}
